.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: calc(11px + (15 - 11) * ((100vw - 320px) / (1920 - 320)))
}

.main-wrap {
    padding: 0px 15px
}

section,
.section-p-tb {
    padding-top: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)))
}

.section-p-t {
    padding-top: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)))
}

.section-p-b {
    padding-bottom: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)))
}

.mb-xxl {
    margin-bottom: calc(75px + (100 - 75) * ((100vw - 320px) / (1920 - 320)))
}

.title-mb {
    line-height: 22px;
    margin-top: -2px;
    margin-bottom: calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.bg-theme-theme {
    background-color: #0e9146
}

.border-theme {
    border: 1px solid #0aa592
}

.bg-theme-theme-light {
    background-color: #edf8f8
}

.border-theme-light {
    border: 1px solid #e6f5f5
}

.bg-theme-blue {
    background-color: #effefc
}

.border-blue {
    border: 1px solid #e5fdfa
}

.bg-theme-yellow {
    background-color: #fff9d8
}

.border-yellow {
    border: 1px solid #fff7ce
}

.bg-theme-yellow-light {
    background-color: #fff8ed
}

.border-yellow-light {
    border: 1px solid #fff4e3
}

.bg-theme-orange {
    background-color: #fff2ec
}

.border-orange {
    border: 1px solid #ffebe2
}

.bg-theme-orange-light {
    background-color: #fff6f3
}

.border-orange-light {
    border: 1px solid #ffeee9
}

.bg-theme-pink {
    background-color: #fff2f2
}

.border-pink {
    border: 1px solid #ffe8e8
}

.bg-theme-purple {
    background-color: #fdf7ff
}

.border-purple {
    border: 1px solid #faedff
}

.bg-theme-grey-light {
    background-color: #fafafa
}

.border-grey-light {
    border: 1px solid #f5f5f5
}

.bg-theme-sk-grey {
    background-color: #f3f3f3
}

.border-sk-grey {
    border: 1px solid #eee
}

.badges-round {
    padding: 2px 10px;
    border-radius: 100px
}

.badges {
    padding: 2px 10px;
    border-radius: 3px
}

.fw-600 {
    font-weight: 600
}

.plus-minus {
    border: 1px solid rgba(11, 175, 154, 0.09);
    border-radius: 5px;
    padding: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    text-align: center
}

.plus-minus input {
    background-color: #fff;
    color: #0e9146;
    border: none;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    outline: none;
    width: 35px;
    text-align: center
}

.plus-minus .sub,
.plus-minus .add {
    width: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    height: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    cursor: pointer
}

.circle-shape-wrap {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute
}

.circle-shape-wrap .circle-shape {
    background-color: #edf8f8;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    z-index: 10;
    position: relative
}

.circle-shape-wrap .circle-shape::before,
.circle-shape-wrap .circle-shape::after {
    position: absolute;
    background-color: inherit;
    border-radius: inherit;
    content: "";
    width: inherit;
    height: inherit;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.circle-shape-wrap .circle-shape::before {
    top: -50px
}

.circle-shape-wrap .circle-shape::after {
    bottom: -55px
}

.circle-shape-wrap.left {
    left: -7px
}

.circle-shape-wrap.right {
    right: -7px
}

.circle-5 {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute
}

.circle-5 .circle-shape {
    background-color: #fff;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    z-index: 10;
    position: relative;
    top: -5px
}

.circle-5 .circle-shape:last-of-type {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.circle-5 .circle-shape:last-of-type::before {
    display: none
}

.circle-5 .circle-shape:last-of-type::after {
    bottom: -25px
}

.circle-5 .circle-shape::before,
.circle-5 .circle-shape::after {
    position: absolute;
    background-color: inherit;
    border-radius: inherit;
    content: "";
    width: inherit;
    height: inherit;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.circle-5 .circle-shape::before {
    top: -24px
}

.circle-5 .circle-shape::after {
    bottom: -75px
}

.slick-dots {
    text-align: center;
    position: relative;
    bottom: 0px;
    left: 0;
    right: 0;
    margin-top: calc(-22px + (-36 + 22) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.slick-dots li {
    position: relative;
    display: inline-block;
    height: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
    width: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin: 0 3px;
    border-radius: 100%;
    background-color: #0e9146;
    border: 1px solid #ceefeb;
    padding: 0;
    cursor: pointer
}

[class="dark"] .slick-dots li {
    border-color: #0e9146
}

.slick-dots li button {
    display: none
}

.slick-dots .slick-active {
    background-color: #0e9146 !important;
    height: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
    width: calc(32px + (40 - 32) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 20px
}

.rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 4px
}

.rating svg {
    width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    height: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    stroke: #ffbf13;
    fill: #ffbf13
}

.slick-prev,
.slick-next {
    display: none !important
}

.dark .datepicker {
    background-color: #222;
    border: #777
}

.dark .datepicker .datepicker--nav {
    border-bottom: 1px solid #777
}

.dark .datepicker .datepicker--nav .datepicker--nav-title {
    color: #f0f0f0
}

.dark .datepicker .datepicker--cell {
    color: #777
}

.dark .datepicker .datepicker--cell.-selected-,
.dark .datepicker .datepicker--cell.-focus-,
.dark .datepicker .datepicker--cell.-current- {
    background-color: #222;
    border: 1px solid #777;
    color: #f0f0f0
}

.dark .datepicker .datepicker--cell-day.-other-month- {
    color: rgba(119, 119, 119, 0.5) !important
}

.dark .datepicker .datepicker--pointer {
    background-color: #222;
    border-top: 1px solid #777;
    border-right: 1px solid #777
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0
}

html {
    font-size: 16px
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(128, 128, 128, 0.192);
    background-color: #f5f5f5;
    border-radius: 100px
}

::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    background-color: #f5f5f5;
    border-radius: 100px
}

::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.363);
    border-radius: 100px
}

body {
    position: relative;
    font-size: 14px;
    margin: 0;
    color: #222;
    background-color: #fff
}

body::before {
    display: none;
    content: "";
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 5;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover
}

body.bluer>* {
    -webkit-filter: blur(2px);
    filter: blur(2px)
}

body.bluer .header-sidebar,
body.bluer #myScrollspy,
body.bluer .offcanvas {
    -webkit-filter: unset !important;
    filter: unset !important
}

body.bluer::before {
    display: block
}

@media only screen and (min-width: 800px) {
    body {
        max-width: 767px;
        margin: 0 auto
    }
}

.custom-scroll-hidden {
    overflow-x: hidden
}

.custom-scroll-hidden:hover {
    overflow-x: auto
}

@media only screen and (max-width: 800px) {
    .custom-scroll-hidden {
        overflow-x: auto
    }

    .custom-scroll-hidden::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important
    }
}

button {
    border: none;
    outline: none;
    background-color: unset
}

ul {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0
}

input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none
}

html {
    scroll-behavior: smooth;
    font-size: 16px
}

@font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 200;
    src: url("../fonts/mulish/mulish-v7-latin-200.eot");
    src: local(""), url("../fonts/mulish/mulish-v7-latin-200d41d.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-200.woff2") format("woff2"), url("../fonts/mulish/mulish-v7-latin-200.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-200.ttf") format("truetype"), url("../fonts/mulish/mulish-v7-latin-200.svg#Mulish") format("svg")
}

@font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/mulish/mulish-v7-latin-300.eot");
    src: local(""), url("../fonts/mulish/mulish-v7-latin-300d41d.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-300.woff2") format("woff2"), url("../fonts/mulish/mulish-v7-latin-300.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-300.ttf") format("truetype"), url("../fonts/mulish/mulish-v7-latin-300.svg#Mulish") format("svg")
}

@font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/mulish/mulish-v7-latin-regular.eot");
    src: local(""), url("../fonts/mulish/mulish-v7-latin-regulard41d.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-regular.woff2") format("woff2"), url("../fonts/mulish/mulish-v7-latin-regular.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-regular.ttf") format("truetype"), url("../fonts/mulish/mulish-v7-latin-regular.svg#Mulish") format("svg")
}

@font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/mulish/mulish-v7-latin-500.eot");
    src: local(""), url("../fonts/mulish/mulish-v7-latin-500d41d.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-500.woff2") format("woff2"), url("../fonts/mulish/mulish-v7-latin-500.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-500.ttf") format("truetype"), url("../fonts/mulish/mulish-v7-latin-500.svg#Mulish") format("svg")
}

@font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/mulish/mulish-v7-latin-600.eot");
    src: local(""), url("../fonts/mulish/mulish-v7-latin-600d41d.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-600.woff2") format("woff2"), url("../fonts/mulish/mulish-v7-latin-600.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-600.ttf") format("truetype"), url("../fonts/mulish/mulish-v7-latin-600.svg#Mulish") format("svg")
}

@font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 800;
    src: url("../fonts/mulish/mulish-v7-latin-800.eot");
    src: local(""), url("../fonts/mulish/mulish-v7-latin-800d41d.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-800.woff2") format("woff2"), url("../fonts/mulish/mulish-v7-latin-800.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-800.ttf") format("truetype"), url("../fonts/mulish/mulish-v7-latin-800.svg#Mulish") format("svg")
}

@font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/mulish/mulish-v7-latin-700.eot");
    src: local(""), url("../fonts/mulish/mulish-v7-latin-700d41d.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-700.woff2") format("woff2"), url("../fonts/mulish/mulish-v7-latin-700.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-700.ttf") format("truetype"), url("../fonts/mulish/mulish-v7-latin-700.svg#Mulish") format("svg")
}

@font-face {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/mulish/mulish-v7-latin-900.eot");
    src: local(""), url("../fonts/mulish/mulish-v7-latin-900d41d.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-900.woff2") format("woff2"), url("../fonts/mulish/mulish-v7-latin-900.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-900.ttf") format("truetype"), url("../fonts/mulish/mulish-v7-latin-900.svg#Mulish") format("svg")
}

@font-face {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/quicksand/quicksand-v24-latin-300.eot");
    src: local(""), url("../fonts/quicksand/quicksand-v24-latin-300d41d.eot?#iefix") format("embedded-opentype"), url("../fonts/quicksand/quicksand-v24-latin-300.woff2") format("woff2"), url("../fonts/quicksand/quicksand-v24-latin-300.woff") format("woff"), url("../fonts/quicksand/quicksand-v24-latin-300.ttf") format("truetype"), url("../fonts/quicksand/quicksand-v24-latin-300.svg#Quicksand") format("svg")
}

@font-face {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/quicksand/quicksand-v24-latin-regular.eot");
    src: local(""), url("../fonts/quicksand/quicksand-v24-latin-regulard41d.eot?#iefix") format("embedded-opentype"), url("../fonts/quicksand/quicksand-v24-latin-regular.woff2") format("woff2"), url("../fonts/quicksand/quicksand-v24-latin-regular.woff") format("woff"), url("../fonts/quicksand/quicksand-v24-latin-regular.ttf") format("truetype"), url("../fonts/quicksand/quicksand-v24-latin-regular.svg#Quicksand") format("svg")
}

@font-face {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/quicksand/quicksand-v24-latin-500.eot");
    src: local(""), url("../fonts/quicksand/quicksand-v24-latin-500d41d.eot?#iefix") format("embedded-opentype"), url("../fonts/quicksand/quicksand-v24-latin-500.woff2") format("woff2"), url("../fonts/quicksand/quicksand-v24-latin-500.woff") format("woff"), url("../fonts/quicksand/quicksand-v24-latin-500.ttf") format("truetype"), url("../fonts/quicksand/quicksand-v24-latin-500.svg#Quicksand") format("svg")
}

@font-face {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/quicksand/quicksand-v24-latin-600.eot");
    src: local(""), url("../fonts/quicksand/quicksand-v24-latin-600d41d.eot?#iefix") format("embedded-opentype"), url("../fonts/quicksand/quicksand-v24-latin-600.woff2") format("woff2"), url("../fonts/quicksand/quicksand-v24-latin-600.woff") format("woff"), url("../fonts/quicksand/quicksand-v24-latin-600.ttf") format("truetype"), url("../fonts/quicksand/quicksand-v24-latin-600.svg#Quicksand") format("svg")
}

@font-face {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/quicksand/quicksand-v24-latin-700.eot");
    src: local(""), url("../fonts/quicksand/quicksand-v24-latin-700d41d.eot?#iefix") format("embedded-opentype"), url("../fonts/quicksand/quicksand-v24-latin-700.woff2") format("woff2"), url("../fonts/quicksand/quicksand-v24-latin-700.woff") format("woff"), url("../fonts/quicksand/quicksand-v24-latin-700.ttf") format("truetype"), url("../fonts/quicksand/quicksand-v24-latin-700.svg#Quicksand") format("svg")
}

body {
    font-family: "Mulish", "sans-serif"
}

ul {
    list-style: none
}

li {
    font-size: 14px;
    color: #222
}

p {
    font-size: 14px;
    line-height: 1.5;
    color: #222
}

a {
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    text-decoration: none;
    color: #222
}

a:hover {
    text-decoration: none;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    color: currentColor
}

a:focus {
    outline: none
}

:focus {
    outline: none
}

.form-control {
    font-size: 14px;
    color: #222
}

[dir="rtl"] .form-control {
    text-align: right
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

h1 {
    font-size: 38px;
    line-height: 33px;
    text-transform: capitalize;
    margin: 0;
    color: #222
}

h2 {
    font-size: 24px;
    line-height: 22px;
    margin: 0;
    color: #222
}

h3 {
    font-size: 20px;
    line-height: 16px;
    margin: 0;
    color: #222
}

h4 {
    font-size: 18px;
    line-height: 25px;
    margin: 0;
    color: #222
}

h5 {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    font-weight: 400;
    color: #222
}

h6 {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    margin: 0;
    color: #222
}

.font-base {
    font-size: 14px !important
}

.font-xs {
    font-size: calc(12px + (14 - 13) * ((100vw - 320px) / (1920 - 320))) !important
}

.font-sm {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))) !important
}

.font-md {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))) !important
}

.font-lg {
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320))) !important
}

.font-xl {
    font-size: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320))) !important
}

.font-xxl {
    font-size: calc(24px + (30 - 24) * ((100vw - 320px) / (1920 - 320))) !important
}

.font-default {
    font-size: 16px !important
}

.title-2 {
    font-weight: 600;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.title-color {
    color: #000
}

.font-white {
    color: #fff
}

.content-color {
    color: #323232;
}

.font-theme {
    color: #0e9146 !important
}

.font-danger {
    color: #e93939 !important
}

.accordion .accordion-item {
    border: none;
    background-color: #fff !important
}

.accordion .accordion-item .accordion-header {
    padding: 10px 0;
    border-top: 1px solid #f1f1f1;
    background-color: #fff
}

.accordion .accordion-item .accordion-button {
    background-color: #fff;
    color: #222;
    line-height: 23px;
    padding: 0;
    border: none;
    font-weight: 600;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.accordion .accordion-item .accordion-button:focus {
    color: #222;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none
}

.accordion .accordion-item .accordion-button::after {
    background-image: url("../icons/svg/chevron-right.svg")
}

[class="dark"] .accordion .accordion-item .accordion-button::after {
    -webkit-filter: invert(1);
    filter: invert(1)
}

[dir="rtl"] .accordion .accordion-item .accordion-button::after {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.accordion .accordion-item .accordion-button:not(.collapsed) {
    -webkit-box-shadow: none;
    box-shadow: none
}

.accordion .accordion-item .accordion-button:not(.collapsed)::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.accordion .accordion-item .accordion-body {
    padding: 0 0 10px 0;
    margin-top: -5px;
    background-color: #fff
}

.accordion .accordion-item .accordion-body p {
    margin-bottom: 0
}

.btn-solid {
    padding: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320))) calc(20px + (34 - 20) * ((100vw - 320px) / (1920 - 320)));
    background-color: #0e9146;
    color: #fff;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    font-weight: 600;
    border: 1px solid #ceefeb;
    line-height: 21px;
    display: inline-block
}

[class="dark"] .btn-solid {
    color: #f0f0f0
}

.btn-solid:hover {
    color: #fff
}

[class="dark"] .btn-solid:hover {
    color: #f0f0f0
}

.btn-outline {
    padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) calc(28px + (34 - 28) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    color: #0e9146;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    font-weight: 600;
    border: 1px solid #0e9146;
    display: inline-block;
    line-height: 21px
}

.btn-outline:hover {
    color: #0e9146
}

.btn-outline-grey {
    padding: 10px calc(28px + (34 - 28) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    color: #777;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    line-height: 21px;
    font-weight: 600;
    border: 1px solid #cacaca;
    width: 100%;
    display: inline-block
}

.btn-outline-grey:hover {
    color: #777
}

.custom-form input,
.custom-form textarea,
.custom-form select,
.custom-form .form-control {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) 0;
    padding-right: calc(44px + (47 - 44) * ((100vw - 320px) / (1920 - 320)));
    padding-left: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 4px;
    border: 1px solid #ceefeb;
    background-color: #fafafa;
    color: #222
}

.custom-form input::-webkit-input-placeholder,
.custom-form textarea::-webkit-input-placeholder,
.custom-form select::-webkit-input-placeholder,
.custom-form .form-control::-webkit-input-placeholder {
    color: #777
}

.custom-form input::-moz-placeholder,
.custom-form textarea::-moz-placeholder,
.custom-form select::-moz-placeholder,
.custom-form .form-control::-moz-placeholder {
    color: #777
}

.custom-form input:-ms-input-placeholder,
.custom-form textarea:-ms-input-placeholder,
.custom-form select:-ms-input-placeholder,
.custom-form .form-control:-ms-input-placeholder {
    color: #777
}

.custom-form input::-ms-input-placeholder,
.custom-form textarea::-ms-input-placeholder,
.custom-form select::-ms-input-placeholder,
.custom-form .form-control::-ms-input-placeholder {
    color: #777
}

.custom-form input::placeholder,
.custom-form textarea::placeholder,
.custom-form select::placeholder,
.custom-form .form-control::placeholder {
    color: #777
}

.custom-form input:focus,
.custom-form textarea:focus,
.custom-form select:focus,
.custom-form .form-control:focus {
    border-color: #0e9146;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #edf8f8
}

.custom-form .input-box {
    margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    position: relative
}

.custom-form .input-box input,
.custom-form .input-box textarea {
    line-height: 28px;
    padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(44px + (47 - 44) * ((100vw - 320px) / (1920 - 320)));
    padding-left: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .custom-form .input-box input,
[dir="rtl"] .custom-form .input-box textarea {
    padding-left: calc(44px + (47 - 44) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.custom-form .input-box .showHidePassword {
    cursor: pointer
}

.custom-form .input-box input[type="date" i]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 9px;
    z-index: 3;
    margin-left: 0;
    opacity: 0
}

.custom-form .input-box>svg {
    width: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    height: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    stroke-width: 1.5
}

.custom-form .input-box>svg,
.custom-form .input-box i {
    font-size: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
    position: absolute;
    right: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

[dir="rtl"] .custom-form .input-box>svg,
[dir="rtl"] .custom-form .input-box i {
    right: unset;
    left: 16px
}

.custom-form .input-box i {
    right: 15px;
    line-height: 22px
}

[dir="rtl"] .custom-form .input-box i {
    right: unset;
    left: 15px
}

.custom-form .input-box.text-box>svg,
.custom-form .input-box.text-box img {
    top: calc(23px + (26 - 23) * ((100vw - 320px) / (1920 - 320)))
}

.custom-form .input-box span {
    position: absolute;
    right: 14px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 4px;
    background-color: #effefc
}

.custom-form .input-box span svg {
    width: 24px;
    height: 24px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s
}

[dir="rtl"] .custom-form .input-box span {
    right: unset;
    left: 14px
}

.custom-form .input-box select:focus~span svg {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.custom-form .input-box .type-password {
    background-color: #edf8f8;
    padding: 10px;
    border: 1px solid #0e9146;
    border-radius: 4px
}

.custom-form .input-box .type-password label {
    font-weight: 600
}

.custom-form .input-box .type-password input {
    border: none;
    background-color: transparent
}

.custom-form .type-password {
    background-color: #edf8f8;
    padding: 10px;
    border: 1px solid #0e9146;
    border-radius: 4px
}

.custom-form .type-password label {
    font-weight: 600
}

.custom-form .type-password .input-box {
    margin-left: -10px;
    margin-right: -10px
}

.custom-form .type-password .input-box input {
    border: none;
    background-color: transparent
}

.custom-form .type-password .input-box input::-webkit-input-placeholder {
    color: #777
}

.custom-form .type-password .input-box input::-moz-placeholder {
    color: #777
}

.custom-form .type-password .input-box input:-ms-input-placeholder {
    color: #777
}

.custom-form .type-password .input-box input::-ms-input-placeholder {
    color: #777
}

.custom-form .type-password .input-box input::placeholder {
    color: #777
}

.input-box select {
    padding: calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320))) 15px;
    background-color: #fafafa;
    border: 1px solid #ceefeb;
    color: #222;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.input-box select:focus {
    border: 1px solid #ceefeb !important;
    background-color: #fafafa !important
}

.input-box .select-box {
    position: relative
}

.input-box .select-box span {
    position: absolute;
    right: 9px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 4px
}

.input-box .select-box span svg {
    width: 24px;
    height: 24px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s
}

[dir="rtl"] .input-box .select-box span svg {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

[dir="rtl"] .input-box .select-box span {
    right: unset;
    left: 9px
}

.input-box select:focus~span svg {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

[dir="rtl"] .input-box select:focus~span svg {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.input-box input[type="radio"] {
    position: relative;
    margin: 2px 1rem 0 3px;
    cursor: pointer
}

[dir="rtl"] .input-box input[type="radio"] {
    margin: 2px 1px 0 1rem
}

.input-box input[type="radio"]:before {
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: scale(0, 0) translate(-50%, -50%);
    transform: scale(0, 0) translate(-50%, -50%);
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 0.55rem;
    height: 0.55rem;
    background: #0e9146;
    border-radius: 50%
}

[dir="rtl"] .input-box input[type="radio"]:before {
    left: unset;
    right: -2px
}

.input-box input[type="radio"]:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 1.05rem;
    height: 1.05rem;
    background: #fff;
    border: 1px solid #0e9146;
    border-radius: 50%
}

[dir="rtl"] .input-box input[type="radio"]:after {
    left: unset;
    right: -10px
}

.input-box input[type="radio"]:checked:before {
    -webkit-transform: scale(1, 1) translate(-50%, -50%);
    transform: scale(1, 1) translate(-50%, -50%)
}

.input-box input[type="radio"]:checked:after {
    border: 1px solid #0e9146
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
    background-color: #edf8f8 !important;
    color: #222 !important;
    -webkit-box-shadow: 0 0 0 1000px #edf8f8 inset !important;
    -webkit-text-fill-color: #222 !important
}

.datepicker {
    left: 25px !important
}

@media only screen and (max-width: 375px) {
    .datepicker {
        left: 12px !important
    }
}

.datepicker.--day-name {
    color: #fff2ec
}

.datepicker .datepicker--cell.-selected-,
.datepicker .datepicker--cell.-focus-,
.datepicker .datepicker--cell.-current- {
    background-color: #effefc;
    color: #222
}

[dir="rtl"] .datepicker {
    left: calc(199px + (299 - 199) * ((100vw - 320px) / (1920 - 320))) !important
}

@media only screen and (max-width: 575px) {
    [dir="rtl"] .datepicker {
        left: calc(12px + (1794 - 12) * ((100vw - 320px) / (1920 - 320))) !important
    }
}

@media only screen and (max-width: 375px) {
    [dir="rtl"] .datepicker {
        left: 12px !important
    }
}

.datepicker--nav-action path {
    stroke: #222
}

.offcanvas {
    max-width: 767px;
    margin: 0 auto;
    background-color: #fff
}

.offcanvas .offcanvas-header {
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.offcanvas .offcanvas-body {
    padding: 0 calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.offcanvas .offcanvas-footer {
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.action-language {
    height: 262px !important
}

.action-language .offcanvas-body {
    padding: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.action-language .offcanvas-body>h2 {
    margin-top: -4px;
    margin-bottom: 10px
}

.action-language .offcanvas-body .list {
    margin-bottom: -3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px
}

.action-language .offcanvas-body .list li {
    padding: 0
}

.action-language .offcanvas-body .list li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.action-language .offcanvas-body .list li a img {
    width: 30px;
    height: 30px;
    margin-right: 10px
}

[dir="rtl"] .action-language .offcanvas-body .list li a img {
    margin-right: unset;
    margin-left: 10px
}

.shop-fillter {
    height: auto
}

.shop-fillter .offcanvas-header .catagories {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%
}

.shop-fillter .offcanvas-header .catagories h5 {
    font-weight: 600
}

.shop-fillter .offcanvas-header .catagories .reset {
    font-weight: 600;
    color: #0e9146
}

.shop-fillter .offcanvas-body {
    height: 372px
}

.shop-fillter .offcanvas-body .input-box select {
    color: #777;
    font-weight: normal
}

.shop-fillter .offcanvas-body .pack-size {
    margin-top: 30px
}

.shop-fillter .offcanvas-body .pack-size h5 {
    font-weight: 600;
    margin-bottom: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.shop-fillter .offcanvas-body .pack-size .size {
    background-color: #fafafa;
    text-align: center;
    padding: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320)));
    border: 1px solid #ceefeb;
    border-radius: 4px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s
}

.shop-fillter .offcanvas-body .pack-size .size span {
    color: #777
}

.shop-fillter .offcanvas-body .pack-size .size.active {
    background-color: #0e9146
}

.shop-fillter .offcanvas-body .pack-size .size.active span {
    color: #fff
}

[class="dark"] .shop-fillter .offcanvas-body .pack-size .size.active span {
    color: #f0f0f0
}

.shop-fillter .offcanvas-body .prize-select {
    margin-top: 30px
}

.shop-fillter .offcanvas-body .prize-select h5 {
    font-weight: 600;
    margin-bottom: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.shop-fillter .offcanvas-body .prize-select .pricing-slider .irs-min,
.shop-fillter .offcanvas-body .prize-select .pricing-slider .irs-max {
    display: none
}

.shop-fillter .offcanvas-body .prize-select .pricing-slider .irs-from,
.shop-fillter .offcanvas-body .prize-select .pricing-slider .irs-to,
.shop-fillter .offcanvas-body .prize-select .pricing-slider .irs-single {
    background-color: #fafafa;
    color: #222;
    font-size: 14px;
    padding: 5px 10px
}

.shop-fillter .offcanvas-body .prize-select .pricing-slider .irs-from,
.shop-fillter .offcanvas-body .prize-select .pricing-slider .irs-to {
    display: inline-block;
    top: 50px
}

.shop-fillter .offcanvas-body .prize-select .pricing-slider .irs-from {
    left: 18px
}

.shop-fillter .offcanvas-body .prize-select .pricing-slider .irs-to {
    right: 18px;
    left: unset
}

.shop-fillter .offcanvas-body .prize-select .pricing-slider .irs-line {
    height: 4px;
    border: none;
    background-color: #fafafa;
    background: #fafafa;
    border-radius: 2px
}

.shop-fillter .offcanvas-body .prize-select .pricing-slider .irs-bar {
    height: 4px;
    border: none;
    background-color: #0e9146;
    background: #0e9146;
    border-radius: 2px
}

.shop-fillter .offcanvas-body .prize-select .pricing-slider .irs-slider {
    height: 14px;
    width: 14px;
    background: #0e9146;
    border: 1px solid #0e9146;
    top: 62%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.shop-fillter .offcanvas-body .prize-select .pricing-slider .irs-slider:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #0e9146;
    width: 12.5px;
    height: 12.5px;
    border-radius: 100%
}

.shop-fillter .offcanvas-footer .btn-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 17px
}

.shop-fillter .offcanvas-footer .btn-box button {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font-weight: 600;
    border: 1px solid #0e9146
}

.offer-filter .offcanvas-body {
    height: auto;
}

.order-history-filter .offcanvas-header {
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320))) calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320))) 20px calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.order-history-filter .offcanvas-body {
    padding: 0 calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    max-height: 372px
}

.order-history-filter .offcanvas-footer {
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.select-offcanvas {
    height: auto
}

.select-offcanvas .offcanvas-header .offcanvas-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px
}

.select-offcanvas .offcanvas-body {
    max-height: 197px;
    overflow-y: auto
}

.select-offcanvas .offcanvas-body .filter-row li .filter-col {
    border: 1px solid #f1f1f1;
    background-color: #fafafa;
    padding: 11px 11px;
    font-size: 14px;
    border-radius: 5px;
    position: relative
}

.select-offcanvas .offcanvas-body .filter-row li .filter-col .check {
    padding: 2px 8px 4px;
    border-radius: 0px 5px 0px 5px;
    background-color: #0e9146;
    -webkit-transform: scale(0);
    transform: scale(0);
    position: absolute;
    right: -3px;
    top: -3px
}

[dir="rtl"] .select-offcanvas .offcanvas-body .filter-row li .filter-col .check {
    left: -3px;
    right: unset;
    border-radius: 5px 0px 5px 0px
}

.select-offcanvas .offcanvas-body .filter-row li .filter-col .check img {
    width: 15px;
    height: 15px
}

.select-offcanvas .offcanvas-body .filter-row li.active .filter-col {
    border-color: #0e9146
}

.select-offcanvas .offcanvas-body .filter-row li.active .filter-col .check {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.select-offcanvas .offcanvas-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px
}

.select-offcanvas .offcanvas-footer button {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.action-confirmation {
    text-align: center;
    height: auto
}

.action-confirmation .offcanvas-body {
    padding: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.action-confirmation .offcanvas-body .confirmation-box h2 {
    margin-bottom: 15px
}

.action-confirmation .offcanvas-body .confirmation-box .btn-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.action-confirmation .offcanvas-body .confirmation-box .btn-box button,
.action-confirmation .offcanvas-body .confirmation-box .btn-box a {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100%;
    padding: 10px;
    margin-top: 15px
}

.action-confirmation .offcanvas-body .confirmation-box .btn-box button:first-of-type,
.action-confirmation .offcanvas-body .confirmation-box .btn-box a:first-of-type {
    margin-right: 15px
}

[dir="rtl"] .action-confirmation .offcanvas-body .confirmation-box .btn-box button:first-of-type,
[dir="rtl"] .action-confirmation .offcanvas-body .confirmation-box .btn-box a:first-of-type {
    margin-right: unset;
    margin-left: 15px
}

.action-confirmation .offcanvas-body .confirmation-box .btn-box .btn-solid {
    color: #f0f0f0
}

.offer-offcanvas {
    border: none;
    height: auto
}

.offer-offcanvas .offcanvas-header {
    background-color: #0e9146;
    border-radius: 15px 15px 0px 0px;
    padding: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    gap: 10px
}

.offer-offcanvas .offcanvas-header .offcanvas-title {
    font-weight: 700;
    margin-top: -6px;
    margin-bottom: -7px;
    color: #f0f0f0
}

.offer-offcanvas .offcanvas-header span {
    font-weight: normal;
    margin-top: -3px;
    margin-bottom: -3px;
    color: #f0f0f0
}

.offer-offcanvas .offcanvas-header .code {
    padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) 20px;
    background-color: rgba(237, 248, 248, 0.2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    border-radius: 5px
}

.offer-offcanvas .offcanvas-header .code button {
    border-radius: 100px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding: 8px calc(14px + (34 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 21px;
    background-color: #f0f0f0
}

.offer-offcanvas .offcanvas-body {
    padding: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.offer-offcanvas .offcanvas-body h6 {
    font-weight: 600;
    margin-top: -3px;
    margin-bottom: -2px
}

.offer-offcanvas .offcanvas-body ol {
    padding-left: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 0
}

.offer-offcanvas .offcanvas-body ol li {
    margin-top: 15px;
    margin-bottom: -2px;
    text-align: justify;
    word-break: normal;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto
}

.add-address,
.add-card {
    height: auto
}

.add-address .offcanvas-header h5,
.add-card .offcanvas-header h5 {
    margin-top: -4px;
    margin-bottom: -4px
}

.add-address .offcanvas-body .custom-form .input-box input:not(:first-of-type),
.add-card .offcanvas-body .custom-form .input-box input:not(:first-of-type) {
    margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.add-address .offcanvas-footer .btn-box,
.add-card .offcanvas-footer .btn-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.add-address .offcanvas-footer .btn-box button,
.add-card .offcanvas-footer .btn-box button {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100%;
    padding: 10px
}

[dir="rtl"] .add-address .offcanvas-footer .btn-box button:first-of-type,
[dir="rtl"] .add-card .offcanvas-footer .btn-box button:first-of-type {
    margin-right: unset;
    margin-left: 15px
}

.add-card .offcanvas-body .custom-form .expriydate {
    padding-right: calc(40px + (44 - 40) * ((100vw - 320px) / (1920 - 320)));
    padding-left: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .add-card .offcanvas-body .custom-form .expriydate {
    padding-left: calc(40px + (44 - 40) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.addtohome-popup {
    height: 160px
}

.addtohome-popup .offcanvas-body {
    padding: 20px
}

.addtohome-popup .install-app {
    margin-top: 20px;
    font-size: 14px;
    float: right
}

[dir="rtl"] .addtohome-popup .install-app {
    float: left
}

.addtohome-popup .app-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.addtohome-popup .app-info img {
    width: 40px;
    height: auto
}

.addtohome-popup .app-info .content {
    padding-left: 15px;
    width: 100%
}

[dir="rtl"] .addtohome-popup .app-info .content {
    padding-left: 0;
    padding-right: 15px
}

.addtohome-popup .app-info .content h3 {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    color: #222;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.addtohome-popup .app-info .content h3 .btn-close {
    width: 24px;
    height: 24px;
    margin-left: auto
}

[class="dark"] .addtohome-popup .app-info .content h3 .btn-close {
    -webkit-filter: invert(1);
    filter: invert(1)
}

.addtohome-popup .app-info .content a {
    font-size: 14px;
    color: #777
}

.action-share {
    height: auto
}

.action-share .offcanvas-header h5 {
    font-weight: 600
}

.action-share .offcanvas-header span {
    cursor: pointer
}

.action-share .offcanvas-header span svg {
    width: 20px;
    height: 20px;
    stroke: #222
}

.action-share .offcanvas-body {
    padding-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.action-share .offcanvas-body ul {
    max-height: 380px
}

.action-share .offcanvas-body ul li .filter-col {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border: none !important
}

.action-share .offcanvas-body ul li .filter-col a {
    line-height: 9px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.action-share .offcanvas-body ul li .filter-col .icon {
    width: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
    height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.action-share .offcanvas-body ul li .filter-col .icon.facebook {
    background-color: #385cb4
}

.action-share .offcanvas-body ul li .filter-col .icon.whatsapp {
    background-color: #1abc56
}

.action-share .offcanvas-body ul li .filter-col .icon.twitter {
    background-color: #00a0dd
}

.action-share .offcanvas-body ul li .filter-col .icon.linkdin {
    background-color: #0077b5
}

.action-share .offcanvas-body ul li .filter-col .icon.instagram {
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)
}

.action-share .offcanvas-body ul li .filter-col .icon.google {
    background: #db3236
}

.action-share .offcanvas-body ul li .filter-col .icon svg {
    width: 20px;
    height: 20px;
    fill: #fff
}

[class="dark"] .action-share .offcanvas-body ul li .filter-col .icon svg {
    fill: #f0f0f0
}

.action-share .offcanvas-body ul.grid {
    max-height: 280px
}

.action-share .offcanvas-body ul.grid li .filter-col {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.action-share .offcanvas-body ul.grid li .filter-col a {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 3px
}

.action-share .offcanvas-body ul.grid li .filter-col a .icon {
    width: calc(44px + (50 - 44) * ((100vw - 320px) / (1920 - 320)));
    padding: 8px;
    height: calc(44px + (50 - 44) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 0;
    margin-bottom: 10px;
    border-radius: 5px
}

.action-share .offcanvas-body ul.grid li .filter-col a .icon svg {
    width: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
    height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)))
}

.action-share .offcanvas-body ul.grid li .filter-col::after {
    display: none
}

.all-review-offcanvas {
    height: 440px
}

.all-review-offcanvas .offcanvas-header h5 {
    font-weight: 600
}

.all-review-offcanvas .offcanvas-header span {
    cursor: pointer
}

.all-review-offcanvas .offcanvas-header span svg {
    width: 20px;
    height: 20px;
    stroke: #222
}

.all-review-offcanvas .offcanvas-body {
    padding-top: 0
}

.all-review-offcanvas .offcanvas-body .review-box {
    padding: 17px;
    margin-top: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    background-color: #fafafa
}

.all-review-offcanvas .offcanvas-body .review-box:first-of-type {
    margin-top: 0
}

.all-review-offcanvas .offcanvas-body .review-box .media img {
    width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
    height: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 100%
}

.all-review-offcanvas .offcanvas-body .review-box .media .rating {
    gap: 2px
}

.all-review-offcanvas .offcanvas-body .review-box .media .rating svg {
    width: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    height: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.all-review-offcanvas .offcanvas-body .review-box .media .media-body h4 {
    font-weight: 600;
    margin-top: -4px
}

.all-review-offcanvas .offcanvas-body .review-box p {
    margin-bottom: 0;
    margin-top: 5px;
    margin-bottom: -2px
}

.nav-tab,
.nav-tab2 {
    margin-left: -15px;
    margin-right: -15px;
    padding: 10px 15px;
    background-color: #fafafa;
    margin-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow-x: hidden;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 15px
}

.nav-tab:hover,
.nav-tab2:hover {
    overflow-x: auto
}

@media only screen and (max-width: 800px) {

    .nav-tab,
    .nav-tab2 {
        overflow-x: auto
    }
}

.nav-tab .nav-link,
.nav-tab2 .nav-link {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding: 0;
    background-color: transparent;
    color: #222;
    font-weight: 600;
    font-size: calc(12px + (18 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.nav-tab .nav-link.active,
.nav-tab2 .nav-link.active {
    color: #0e9146 !important;
    background-color: transparent
}

.nav-tab2 {
    padding: 0;
    gap: 0
}

.nav-tab2 .nav-item .nav-link {
    border-radius: 0;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    padding: 6px calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    font-weight: normal
}

.nav-tab2 .nav-item .nav-link.active {
    border-bottom-color: #0e9146
}

.product-card {
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    min-height: calc(342px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.product-card .commission {
    position: absolute;
    right: 0;
    top: 5px;
    color: rgba(34, 34, 34, 0.65);
    font-size: calc(12 + (24 - 18) * ((100vw - 320px) / (1920 - 320))) !important;
}

[dir="rtl"] .product-card i {
    left: 10px;
    right: unset
}

.product-card .iconly-Heart {
    -webkit-transition: all 0.4s;
    transition: all 0.4s
}

.product-card .iconly-Heart.icbo {
    color: #0e9146
}

.product-card .img-wrap {
    margin-bottom: 10px
}

.product-card .img-wrap a {
    display: block
}

.product-card .img-wrap a img {
    width: 70%;
    margin: 0 auto
}

.product-card .content-wrap {
    margin-bottom: -5px;
    padding: 10px;
}

[dir="rtl"] .product-card .content-wrap {
    text-align: right
}

.product-card .content-wrap a {
    font-weight: 600;
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-weight: 600
}

.product-card .content-wrap span:first-of-type {
    display: block
}

.product-card .content-wrap span:last-of-type {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    line-height: 22px;
    font-weight: 600
}

.product-card .content-wrap span:last-of-type .plus-theme {
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    right: 0;
    bottom: 5px
}

.product-card .content-wrap span:last-of-type .plus-minus {
    position: absolute;
    left: 0;
    right: -100px;
    opacity: 0;
    visibility: hidden;
    bottom: 3px;
    top: -3px;
    padding: 5px 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    width: 100%
}

[dir="rtl"] .product-card .content-wrap span:last-of-type .plus-minus {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    left: -100px;
    right: 0
}

.product-card .content-wrap span:last-of-type .plus-minus input {
    padding: 5px 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    height: 100%;
    background-color: #0e9146;
    color: #fff
}

[class="dark"] .product-card .content-wrap span:last-of-type .plus-minus input {
    color: #f0f0f0
}

.product-card .content-wrap span:last-of-type .plus-minus svg {
    stroke: #fff;
    width: calc(18px + (21 - 18) * ((100vw - 320px) / (1920 - 320)));
    height: calc(18px + (21 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.product-card .content-wrap span:last-of-type .plus-minus svg:first-of-type {
    margin: 0 5px 0 0
}

.product-card .content-wrap span:last-of-type .plus-minus svg:last-of-type {
    margin: 0 0 0 5px
}

.product-card .content-wrap span:last-of-type.active {
    line-height: calc(24px + (29 - 24) * ((100vw - 320px) / (1920 - 320)));
    display: block
}

.product-card .content-wrap span:last-of-type.active .plus-theme {
    bottom: 0;
    right: -100px;
    height: 30px;
    opacity: 0;
    visibility: hidden;
    position: absolute
}

[dir="rtl"] .product-card .content-wrap span:last-of-type.active .plus-theme {
    left: -100px;
    right: unset
}

.product-card .content-wrap span:last-of-type.active .plus-minus {
    right: 0;
    opacity: 1;
    visibility: visible
}

[dir="rtl"] .product-card .content-wrap span:last-of-type.active .plus-minus {
    left: 0;
    right: unset
}

[dir="rtl"] .product-card .content-wrap span:last-of-type {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.product-card .content-wrap span:last-of-type span {
    padding: 4px;
    background-color: #0e9146;
    border-radius: 6px
}

.product-card .content-wrap span:last-of-type span svg {
    stroke: #fff;
    width: calc(16px + (21 - 16) * ((100vw - 320px) / (1920 - 320)));
    height: calc(16px + (21 - 16) * ((100vw - 320px) / (1920 - 320)))
}

[class="dark"] .product-card .content-wrap span:last-of-type span svg {
    stroke: #f0f0f0
}

.product-list.media {
    position: relative;
    padding: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    border-radius: 9px
}

@media only screen and (max-width: 375px) {
    .product-list.media {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

.product-list.media:not(:first-of-type) {
    margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.product-list.media>a {
    width: 70px;
    height: auto;
    padding-right: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    border-right: 1px solid #f1f1f1
}

[dir="rtl"] .product-list.media>a {
    padding-left: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    padding-right: unset;
    border-left: 2px solid #f1f1f1;
    border-right: unset
}

.product-list.media>a img {
    width: 100%
}

.product-list.media .media-body {
    width: calc(100% - 170px)
}

@media (max-width: 375px) {
    .product-list.media .media-body {
        width: calc(100% - 70px)
    }
}

.product-list.media .media-body a {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden
}



[dir="rtl"] .product-list.media .plus-minus {
    left: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    right: unset
}

@media only screen and (max-width: 375px) {
    .product-list.media .plus-minus {
        position: relative;
        bottom: unset;
        right: unset;
        margin-top: 8px;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex
    }

    [dir="rtl"] .product-list.media .plus-minus {
        left: unset;
        right: 0
    }
}

.product-list.media .media-body>a {
    margin-top: -5px;
    font-weight: 600
}

.product-list.media .media-body>span:first-of-type {
    display: block;
    font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320))) !important
}

.product-list.media .media-body>span:last-of-type {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 600;
    gap: 10px
}

.product-list.media .media-body>span:last-of-type .badges-round {
    font-weight: normal;
    color: #fff;
    font-size: 10px
}

[class="dark"] .product-list.media .media-body>span:last-of-type .badges-round {
    color: #f0f0f0
}

.ratio_40 .bg-size:before {
    padding-top: 40%;
    content: "";
    display: block
}

.ratio_45 .bg-size:before {
    padding-top: 45%;
    content: "";
    display: block
}

.ratio2_1 .bg-size:before {
    padding-top: 25%;
    content: "";
    display: block
}

.ratio2_3 .bg-size:before {
    padding-top: 60%;
    content: "";
    display: block
}

.ratio-68 .bg-size:before {
    padding-top: 68%;
    content: "";
    display: block
}

.ratio3_2 .bg-size:before {
    padding-top: 66.66%;
    content: "";
    display: block
}

.ratio_landscape .bg-size:before {
    padding-top: 75%;
    content: "";
    display: block
}

.ratio-83 .bg-size:before {
    padding-top: 83%;
    content: "";
    display: block
}

.ratio_square .bg-size:before {
    padding-top: 100%;
    content: "";
    display: block
}

.ratio_94 .bg-size:before {
    padding-top: 94%;
    content: "";
    display: block
}

.ratio_115 .bg-size:before {
    padding-top: 115%;
    content: "";
    display: block
}

.ratio_125 .bg-size:before {
    padding-top: 125%;
    content: "";
    display: block
}

.ratio_123 .bg-size:before {
    padding-top: 123%;
    content: "";
    display: block
}

.ratio_asos .bg-size:before {
    padding-top: 127.7777778%;
    content: "";
    display: block
}

.ratio_portrait .bg-size:before {
    padding-top: 150%;
    content: "";
    display: block
}

.ratio1_2 .bg-size:before {
    padding-top: 200%;
    content: "";
    display: block
}

.b-top {
    background-position: top !important
}

.b-bottom {
    background-position: bottom !important
}

.b-center {
    background-position: center !important
}

.b_size_content {
    background-size: contain !important;
    background-repeat: no-repeat
}

.dark-switch {
    position: relative;
    width: 60px;
    height: 30px;
    border-radius: 40px
}

.dark-switch input[type="checkbox"] {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0px;
    cursor: pointer;
    opacity: 0;
    z-index: 2
}

.dark-switch span {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
    opacity: 1;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 40px;
    -webkit-transition: 0.2s ease background-color, 0.2s ease opacity;
    transition: 0.2s ease background-color, 0.2s ease opacity
}

.dark-switch span.before-none::before {
    background-color: transparent !important
}

.dark-switch span:before,
.dark-switch span:after {
    content: "";
    position: absolute;
    top: 6px;
    width: 18px;
    height: 18px;
    opacity: 0.5;
    border-radius: 50%;
    -webkit-transition: 0.5s ease transform, 0.2s ease background-color;
    transition: 0.5s ease transform, 0.2s ease background-color
}

.dark-switch span:before {
    background-color: #fff;
    -webkit-transform: translate(-58px, 0px);
    transform: translate(-58px, 0px);
    z-index: 1
}

.dark-switch span:after {
    background-color: #222;
    -webkit-transform: translate(8px, 0px);
    transform: translate(8px, 0px);
    z-index: 0
}

[dir="rtl"] .dark-switch span:after {
    -webkit-transform: translate(-8px, 0px);
    transform: translate(-8px, 0px)
}

.dark-switch input[type="checkbox"]:checked+span {
    background-color: #fff
}

.dark-switch input[type="checkbox"]:active+span {
    opacity: 0.5
}

.dark-switch input[type="checkbox"]:checked+span:before {
    background-color: #fff;
    -webkit-transform: translate(28px, -6px);
    transform: translate(28px, -6px);
    opacity: 1
}

[dir="rtl"] .dark-switch input[type="checkbox"]:checked+span:before {
    -webkit-transform: translate(-28px, -6px);
    transform: translate(-28px, -6px)
}

.dark-switch input[type="checkbox"]:checked+span:after {
    background-color: #0e9146;
    -webkit-transform: translate(34px, -1px);
    transform: translate(34px, -1px);
    opacity: 1
}

[dir="rtl"] .dark-switch input[type="checkbox"]:checked+span:after {
    -webkit-transform: translate(-34px, -1px);
    transform: translate(-34px, -1px)
}

.header {
    padding: calc(7px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.header .logo-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: calc(12px + (12 - 7) * ((100vw - 320px) / (1920 - 320)))
}

.header .logo-wrap i {
    font-size: 20px;
    color: #222
}

.header .logo-wrap a {
    display: contents;
    font-weight: 600
}

.header .logo-wrap a i {
    font-weight: 200;
    font-size: 24px;
    cursor: pointer
}

[dir="rtl"] .header .logo-wrap a i {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.header .logo-wrap .logo {
    width: calc(100px + (130 - 100) * ((100vw - 320px) / (1920 - 320)));
    height: auto
}

[class="dark"] .header .logo-wrap .logo {
    display: none
}

.header .logo-wrap .logo-w {
    display: none
}

[class="dark"] .header .logo-wrap .logo-w {
    display: block
}

.header .logo-wrap .fk-logo {
    width: calc(24px + (34 - 24) * ((100vw - 320px) / (1920 - 320)));
    height: auto
}

[class="dark"] .header .logo-wrap .fk-logo {
    display: none
}

.header .logo-wrap .fk-logo-w {
    display: none
}

[class="dark"] .header .logo-wrap .fk-logo-w {
    display: block
}

.header .avatar-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)))
}

.header .avatar-wrap>span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700
}

.header .avatar-wrap i {
    font-size: 22px
}

.header .avatar-wrap a svg {
    width: 24px
}

.header .avatar-wrap .lord-icon {
    margin-bottom: -3px
}

.header .avatar-wrap .avatar {
    width: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (45 - 35) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.overlay-sidebar {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 5;
    height: 100vh;
    background-color: transparent
}

.overlay-sidebar.show {
    display: block
}

.header-sidebar {
    position: fixed;
    left: -320px;
    top: 0;
    bottom: 0;
    background-color: #fff;
    width: 320px;
    height: 100vh;
    border-radius: 0 18px 18px 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 5;
    padding: 17px
}

[dir="rtl"] .header-sidebar {
    left: unset;
    border-radius: 18px 0 0 18px;
    right: -320px
}

@media (max-width: 375px) {
    .header-sidebar {
        width: 290px
    }
}

.header-sidebar.show {
    left: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

[dir="rtl"] .header-sidebar.show {
    left: unset;
    right: 0
}

.header-sidebar .user-panel {
    padding: 17px 17px 15px 17px;
    text-align: center;
    margin-bottom: 15px;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #f1f1f1
}

.header-sidebar .user-panel .media {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.header-sidebar .user-panel .media>a img {
    width: 56px;
    height: 56px;
    display: block;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.header-sidebar .user-panel .media .media-body {
    text-align: left
}

[dir="rtl"] .header-sidebar .user-panel .media .media-body {
    text-align: right
}

.header-sidebar .user-panel .media .media-body>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 3px;
    font-weight: 700
}

.header-sidebar .user-panel .media .media-body>a span {
    font-weight: normal;
    display: inline-block
}

.header-sidebar .navigation {
    margin-bottom: 100px
}

.header-sidebar .navigation ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    padding: 8px;
    border-radius: 9px
}

.header-sidebar .navigation ul li:not(:first-of-type) {
    margin-top: 8px
}

.header-sidebar .navigation ul li:last-of-type {
    margin-bottom: 100px
}

.header-sidebar .navigation ul li .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    font-weight: 600;
    gap: 8px;
    color: #222
}

.header-sidebar .navigation ul li .nav-link:hover,
.header-sidebar .navigation ul li .nav-link:focus {
    color: #222
}

.header-sidebar .navigation ul li .nav-link i {
    font-size: 24px
}

.header-sidebar .navigation ul li .nav-link img {
    width: 22px;
    height: 18px
}

.header-sidebar .navigation ul li .nav-link span {
    line-height: 25px
}

.header-sidebar .navigation ul li .arrow {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #edf8f8
}

.header-sidebar .navigation ul li .arrow svg {
    width: 18px;
    height: 18px
}

[dir="rtl"] .header-sidebar .navigation ul li .arrow svg {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.header-sidebar .navigation ul li.active {
    background-color: #0e9146
}

.header-sidebar .navigation ul li.active .nav-link {
    color: #f0f0f0
}

[class="dark"] .header-sidebar .navigation ul li.active .nav-link {
    color: #f0f0f0
}

.header-sidebar .wrap {
    position: relative;
    height: calc(100vh - 174px);
    overflow-y: auto;
    margin-bottom: 300px
}

.header-sidebar .contact-us {
    background-color: #edf8f8;
    margin-top: 120px;
    border-radius: 7px;
    position: absolute;
    bottom: 17px;
    left: 17px;
    right: 17px;
    z-index: 3;
    padding: 13px
}

.header-sidebar .contact-us>span {
    font-weight: 600;
    font-size: 16px;
    display: block;
    margin-bottom: 4px;
    margin-top: -4px
}

.header-sidebar .contact-us p {
    margin-bottom: 12px
}

.header-sidebar .contact-us a {
    padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320))) calc(20px + (26 - 20) * ((100vw - 320px) / (1920 - 320)));
    font-size: 14px;
    line-height: 18px;
    color: #f0f0f0;
    font-weight: 600
}

[class="dark"] .header-sidebar .contact-us a {
    color: #f0f0f0
}

.footer-wrap {
    margin: 0 auto;
    max-width: 800px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: calc(3px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) 0;
    background-color: #0e9146;
    z-index: 2
}

@media only screen and (min-width: 800px) {
    .footer-wrap {
        max-width: 767px
    }
}

.footer-wrap .footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    margin-top: 4px
}

.footer-wrap .footer .footer-item .footer-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 2px;
    position: relative
}

.footer-wrap .footer .footer-item .footer-link i {
    font-size: 20px;
    color: #fff
}

.footer-wrap .footer .footer-item .footer-link .icon {
    width: calc(30px + (32 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (32 - 30) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: -7px
}

.footer-wrap .footer .footer-item .footer-link .offer {
    margin-top: 2px
}

.footer-wrap .footer .footer-item .footer-link span {
    color: #fff;
    margin-bottom: -3px;
    font-size: calc(11px + (15 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.footer-wrap .footer .footer-item .footer-link::after {
    content: "";
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 4px;
    height: 4px;
    display: block;
    background-color: #fff;
    border-radius: 100%;
    top: -8px;
    opacity: 0;
    -webkit-transition: all 0.4s;
    transition: all 0.4s
}

.footer-wrap .footer .footer-item.active .footer-link::after,
.footer-wrap .footer .footer-item:hover .footer-link::after {
    opacity: 1
}

.footer-wrap.shop {
    padding-left: 15px;
    padding-right: 15px;
    max-width: calc(100vw - 30px);
    border-radius: 4px;
    bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320))) 10px
}

@media only screen and (min-width: 800px) {
    .footer-wrap.shop {
        max-width: calc(600px - calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320))))
    }
}

.footer-wrap.shop .footer {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 0
}

.footer-wrap.shop .footer .footer-item {
    opacity: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.footer-wrap.shop .footer .footer-item>span {
    color: #fff
}

.footer-wrap.shop .footer .footer-item>span:last-of-type {
    font-weight: 600
}

.footer-wrap.shop .footer .footer-item>a {
    font-weight: 600;
    color: #fff;
    line-height: 21px;
    margin-right: -7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)))
}

@media only screen and (max-width: 400px) {
    .footer-wrap.shop .footer .footer-item>a {
        font-size: 14px !important
    }
}

[dir="rtl"] .footer-wrap.shop .footer .footer-item>a {
    margin-left: -7px;
    margin-right: unset
}

.footer-wrap.shop .footer .footer-item>a svg {
    margin-bottom: -2.2px;
    width: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
    height: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .footer-wrap.shop .footer .footer-item>a svg {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.footer-wrap.footer-button {
    padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) !important;
    max-width: calc(100vw - calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320))));
    border-radius: 4px;
    text-align: center;
    bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)))
    
}

@media only screen and (min-width: 800px) {
    .footer-wrap.footer-button {
        max-width: calc(600px - calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320))))
    }
}

.footer-wrap.footer-button a {
    width: 100%;
    display: block;
    color: #fff;
    font-weight: 600
}

.about-us-page .banner-box {
    background-color: #edf8f8;
    border-radius: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.about-us-page .banner-box img {
    width: 100%;
    margin-bottom: -10%
}

.about-us-page .who-we-are {
    padding-top: calc(50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)))
}

.about-us-page .who-we-are h2 {
    font-weight: 700;
    margin-bottom: 4px;
    margin-top: -5px;
    font-size: 16px
}

.about-us-page .who-we-are p {
    margin-bottom: 0
}

.about-us-page .how-do-section h3 {
    font-weight: 700;
    margin-top: -3px;
    font-size: 16px
}

.about-us-page .how-do-section .steps-wrap .steps-box {
    margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320))) calc(26px + (30 - 26) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fafafa;
    margin-left: calc(14.5px + (16.5 - 14.5) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    position: relative
}

[dir="rtl"] .about-us-page .how-do-section .steps-wrap .steps-box {
    margin-right: calc(14.5px + (16.5 - 14.5) * ((100vw - 320px) / (1920 - 320)));
    margin-left: unset
}

.about-us-page .how-do-section .steps-wrap .steps-box>span {
    width: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
    display: block;
    font-weight: 600;
    color: #fff;
    background-color: #0e9146;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    left: calc(-14.5px + (-16.5 + 14.5) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

[dir="rtl"] .about-us-page .how-do-section .steps-wrap .steps-box>span {
    right: calc(-14.5px + (-16.5 + 14.5) * ((100vw - 320px) / (1920 - 320)));
    left: unset
}

[class="dark"] .about-us-page .how-do-section .steps-wrap .steps-box>span {
    color: #f0f0f0
}

.about-us-page .how-do-section .steps-wrap .steps-box .content h4 {
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 5px;
    margin-top: -3px;
    font-size: 15px
}

.about-us-page .how-do-section .steps-wrap .steps-box .content p {
    margin-bottom: 0;
    line-height: 20px
}

.about-us-page .team-section .gap-y {
    --bs-gutter-y: calc(40px + (70 - 40) * ((100vw - 320px) / (1920 - 320)))
}

.about-us-page .team-section h5 {
    font-weight: 700;
    margin-bottom: 15px
}

.about-us-page .team-section .member {
    padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.about-us-page .team-section .member .bg-shape {
    width: 100%;
    height: 50%;
    text-align: center;
    border-radius: 10px
}

.about-us-page .team-section .member .bg-shape img {
    margin-top: calc(-20px + (-40 - -20) * ((100vw - 320px) / (1920 - 320)));
    width: 50%
}

.about-us-page .team-section .member h6 {
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
    color: #777
}

.about-us-page .team-section .member .social {
    margin-top: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: -4px
}

.about-us-page .team-section .member .social li svg {
    width: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));
    height: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));
    fill: #0e9146
}

.account-page .account-wrap .user-panel {
    margin-bottom: 20px;
    width: 100%;
    padding: 17px;
    background-color: #fafafa;
    border-radius: 7px
}

.account-page .account-wrap .user-panel .media>a img {
    width: calc(55px + (70 - 55) * ((100vw - 320px) / (1920 - 320)));
    height: calc(55px + (70 - 55) * ((100vw - 320px) / (1920 - 320)));
    display: block;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.account-page .account-wrap .user-panel .media .media-body {
    text-align: left
}

[dir="rtl"] .account-page .account-wrap .user-panel .media .media-body {
    text-align: right
}

.account-page .account-wrap .user-panel .media .media-body>a {
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.account-page .account-wrap .user-panel .media .media-body>a span {
    font-weight: normal;
    display: inline-block;
    margin-top: -1px
}

.account-page .account-wrap .navigation li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px 20px
}


.account-page .account-wrap .navigation li .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    font-weight: 500;
    gap: 10px
}

.account-page .account-wrap .navigation li .nav-link:hover,
.account-page .account-wrap .navigation li .nav-link:focus {
    color: #222
}

.account-page .account-wrap .navigation li .nav-link i {
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.account-page .account-wrap .navigation li .nav-link img {
    width: 22px;
    height: 18px
}

.account-page .account-wrap .navigation li .nav-link span {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.account-page .account-wrap .navigation li .arrow {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #edf8f8
}

[dir="rtl"] .account-page .account-wrap .navigation li .arrow {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.account-page .account-wrap .navigation li .arrow svg {
    width: 18px;
    height: 18px;
    stroke: #0e9146
}

.account-page .account-wrap .log-out {
    padding: 8px 30px;
    font-weight: 600;
    background-color: #fafafa;
    border-radius: 9px;
    margin-top: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px
}

[class="dark"] .account-page .account-wrap .log-out {
    color: #f0f0f0
}

.account-page .account-wrap .log-out i {
    font-size: calc(20px + (22 - 20) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .account-page .account-wrap .log-out i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.bg-pattern-wrap .bg-size:before {
    padding-top: 20%
}

.bg-pattern-wrap .bg-patter {
    background-color: #0e9146
}

.login-page {
    padding-top: 25px;
    background-color: #fff;
    margin-top: -22px;
    border-radius: 22px 22px 0px 0px
}

.login-page .logo {
    width: 130px;
    height: auto
}

[class="dark"] .login-page .logo {
    display: none
}

.login-page .logo.logo-w {
    display: none
}

[class="dark"] .login-page .logo.logo-w {
    display: block
}

.login-page>p {
    line-height: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 15px
}

.login-page>span {
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    margin-bottom: calc(24px + (40 - 34) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .login-page>span {
    -webkit-box-pack: right;
    -ms-flex-pack: right;
    justify-content: right
}

.login-page>span a {
    text-decoration: underline;
    color: #777;
    margin-left: 10px
}

[dir="rtl"] .login-page>span a {
    margin-right: 10px;
    margin-left: unset
}

.login-page>span a svg {
    width: 21px;
    height: 21px
}

.login-page .login-section .custom-form {
    text-align: center
}

.login-page .login-section .custom-form h1 {
    margin-bottom: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
    text-align: left
}

[dir="rtl"] .login-page .login-section .custom-form h1 {
    text-align: right
}

.login-page .login-section .custom-form p {
    text-align: left
}

[dir="rtl"] .login-page .login-section .custom-form p {
    text-align: right
}

.login-page .login-section .custom-form>span {
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    margin-bottom: calc(24px + (40 - 34) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .login-page .login-section .custom-form>span {
    -webkit-box-pack: right;
    -ms-flex-pack: right;
    justify-content: right
}

.login-page .login-section .custom-form>span a {
    text-decoration: underline;
    color: #777;
    margin-left: 4px
}

[dir="rtl"] .login-page .login-section .custom-form>span a {
    margin-right: 4px;
    margin-left: unset
}

.login-page .login-section .custom-form>span a svg {
    width: 21px;
    height: 21px
}

.login-page .login-section .custom-form .forgot {
    margin-top: -12px;
    text-align: right;
    display: block
}

[dir="rtl"] .login-page .login-section .custom-form .forgot {
    text-align: left
}

.login-page .login-section .custom-form .btn-solid {
    width: 100%;
    margin-bottom: 22px;
    font-weight: bold;
    font-size: calc(17px + (19 - 17) * ((100vw - 320px) / (1920 - 320)))
}

.login-page .login-section .socila-section {
    margin-top: 30px
}

.login-page .login-section .socila-section>span {
    width: 100%;
    padding: 2px;
    text-align: center;
    display: block;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(70%, #e8e8e8), color-stop(0%, rgba(255, 255, 255, 0)));
    background-image: linear-gradient(to right, #e8e8e8 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 14px 1px;
    background-repeat: repeat-x
}

.login-page .login-section .socila-section>span>span {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 8px 10px;
    left: 50%
}

.login-page .login-section .socila-section .social-wrap {
    margin-top: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)))
}

.login-page .login-section .socila-section .social-wrap a {
    display: block;
    padding: 14px;
    border-radius: 5px;
    background-color: #fafafa;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 8px
}

.login-page .login-section .socila-section .social-wrap a:not(:first-of-type) {
    margin-top: 15px
}

.login-page .login-section .socila-section .social-wrap a img {
    width: 20px;
    height: auto
}

.login-page .login-section .countdown {
    margin-bottom: calc(34px + (50 - 34) * ((100vw - 320px) / (1920 - 320)))
}

.login-page .login-section .countdown .otp-input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.login-page .login-section .countdown .otp-input input {
    font-weight: 700;
    padding: 0;
    width: 25%;
    height: 50px;
    text-align: center
}

.login-page .login-section .countdown .otp-input input::-webkit-input-placeholder {
    font-weight: 500
}

.login-page .login-section .countdown .otp-input input::-moz-placeholder {
    font-weight: 500
}

.login-page .login-section .countdown .otp-input input:-ms-input-placeholder {
    font-weight: 500
}

.login-page .login-section .countdown .otp-input input::-ms-input-placeholder {
    font-weight: 500
}

.login-page .login-section .countdown .otp-input input::placeholder {
    font-weight: 500
}

.login-page .login-section .otp-countdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: calc(34px + (50 - 34) * ((100vw - 320px) / (1920 - 320)))
}

.login-page .login-section .otp-countdown a {
    text-decoration: underline;
    color: #777
}

.cart-page .cart-item-wrap .swipe-to-show {
    overflow-x: hidden;
    -ms-touch-action: none;
    touch-action: none;
    position: relative
}

.cart-page .cart-item-wrap .swipe-to-show:not(:first-of-type) {
    margin-top: 15px
}

.cart-page .cart-item-wrap .swipe-to-show .media {
    width: 100%;
    background-color: #fafafa;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    position: relative;
    z-index: 1
}

[class="dark"] .cart-page .cart-item-wrap .swipe-to-show .media .media-body span .badges-round {
    color: #f0f0f0
}

.cart-page .cart-item-wrap .swipe-to-show .delete-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    background-color: #0e9146;
    padding: 14px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 0 10px 10px 0;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -60px;
    z-index: 2
}

[dir="rtl"] .cart-page .cart-item-wrap .swipe-to-show .delete-button {
    right: unset;
    border-radius: 10px 0 0 10px;
    left: -60px
}

.cart-page .cart-item-wrap .swipe-to-show .delete-button svg {
    width: calc(20px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    height: calc(20px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    stroke: #fff
}

[class="dark"] .cart-page .cart-item-wrap .swipe-to-show .delete-button svg {
    stroke: #f0f0f0
}

.cart-page .cart-item-wrap .swipe-to-show.active .media {
    margin-left: -40px
}

[dir="rtl"] .cart-page .cart-item-wrap .swipe-to-show.active .media {
    margin-right: -40px;
    margin-left: unset
}

.cart-page .cart-item-wrap .swipe-to-show.active .delete-button {
    right: 0
}

[dir="rtl"] .cart-page .cart-item-wrap .swipe-to-show.active .delete-button {
    left: 0;
    right: unset
}

.cart-page .coupon-ticket-wrap {
    margin-top: 20px
}

.cart-page .coupon-ticket-wrap .coupon-ticket {
    cursor: pointer;
    background-color: #edf8f8;
    padding: 20px;
    position: relative;
    font-family: "Quicksand", "sans-serif"
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media {
    margin-top: calc(-3px + (-7 + 3) * ((100vw - 320px) / (1920 - 320)));
    background-color: transparent;
    gap: calc(6px + (15 - 6) * ((100vw - 320px) / (1920 - 320)));
    padding: 0
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .off {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 4px
}

@media only screen and (max-width: 375px) {
    .cart-page .coupon-ticket-wrap .coupon-ticket .media .off {
        margin-top: -20px
    }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .off>span:first-of-type {
    font-size: calc(26px + (45 - 26) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    color: #0e9146
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .off>span:last-of-type {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .off>span:last-of-type span {
    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: normal;
    color: #0e9146
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .off>span:last-of-type span:first-of-type {
    margin-bottom: calc(-6px + (-10 + 6) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)))
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

@media only screen and (max-width: 375px) {
    .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body {
        position: absolute;
        left: 20px;
        bottom: 18px
    }

    [dir="rtl"] .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body {
        left: unset;
        right: 20px
    }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body h2,
.cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body span {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body h2 {
    line-height: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 0
}

@media only screen and (max-width: 375px) {
    .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body {
        text-align: center
    }

    .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body h2,
    .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body span {
        font-size: 16px
    }

    .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body span {
        display: none
    }

    .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body h2 {
        margin-bottom: 0;
        line-height: 22px
    }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .code {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 6px
}

@media only screen and (max-width: 375px) {
    .cart-page .coupon-ticket-wrap .coupon-ticket .code {
        margin-left: auto
    }

    [dir="rtl"] .cart-page .coupon-ticket-wrap .coupon-ticket .code {
        margin-right: auto;
        margin-left: unset
    }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .code span {
    font-weight: 400;
    color: #222;
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.cart-page .coupon-ticket-wrap .coupon-ticket .code a {
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(3px + (5 - 3) * ((100vw - 320px) / (1920 - 320))) calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    line-height: 22px;
    background-color: #0e9146;
    color: #fff;
    border-radius: 100px
}

[class="dark"] .cart-page .coupon-ticket-wrap .coupon-ticket .code a {
    color: #f0f0f0
}

@media only screen and (max-width: 375px) {
    .cart-page .coupon-ticket-wrap .coupon-ticket .code a {
        font-size: 12px;
        padding: 3px 14px
    }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .big-circle {
    position: absolute;
    top: 50%;
    right: 24%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

[dir="rtl"] .cart-page .coupon-ticket-wrap .coupon-ticket .big-circle {
    right: unset;
    left: 20%
}

@media only screen and (max-width: 375px) {
    .cart-page .coupon-ticket-wrap .coupon-ticket .big-circle {
        right: 30%
    }

    [dir="rtl"] .cart-page .coupon-ticket-wrap .coupon-ticket .big-circle {
        left: 30%;
        right: unset
    }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: transparent;
    display: block;
    position: relative
}

@media only screen and (max-width: 375px) {
    .cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span {
        width: 30px;
        height: 30px
    }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span::before,
.cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span::after {
    content: "";
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background-color: #fff;
    position: absolute
}

.cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span::before {
    top: -50px
}

.cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span::after {
    bottom: -50px
}

@media only screen and (max-width: 375px) {
    .cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span::before {
        top: -45px
    }

    .cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span::after {
        bottom: -45px
    }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .circle-5 {
    top: 32%
}

.cart-page .coupon-ticket-wrap .coupon-ticket .circle-5.left {
    left: -6px
}

.cart-page .coupon-ticket-wrap .coupon-ticket .circle-5.right {
    right: -6px
}

.cart-page .order-detail h3 {
    margin-bottom: 10px
}

.cart-page .order-detail ul li {
    padding: 2px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.cart-page .order-detail ul li span {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    border: none;
    color: #777
}

.cart-page .order-detail ul li:last-child {
    padding: 10px 0;
    font-weight: 600;
    margin-top: 8px;
    border-top: 1px solid #f1f1f1;
    margin-bottom: -5px
}

.cart-page .order-detail ul li:last-child span {
    color: #222
}

.catagories-wide .search-box {
    position: relative
}

.catagories-wide .search-box input[type="search" i]::-webkit-search-cancel-button {
    display: none !important
}

.catagories-wide .search-box i {
    position: absolute;
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    top: 50%;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.catagories-wide .search-box .search {
    left: 16px;
    font-size: 21px
}

.catagories-wide .search-box .mic {
    right: 16px;
    font-size: 23px
}

.catagories-wide .search-box input {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: 10px 47px;
    border-radius: 4px;
    color: #222;
    border: 1px solid #ceefeb;
    background-color: #fafafa
}

.catagories-wide .search-box input::-webkit-input-placeholder {
    color: #cacaca
}

.catagories-wide .search-box input::-moz-placeholder {
    color: #cacaca
}

.catagories-wide .search-box input:-ms-input-placeholder {
    color: #cacaca
}

.catagories-wide .search-box input::-ms-input-placeholder {
    color: #cacaca
}

.catagories-wide .search-box input::placeholder {
    color: #cacaca
}

.catagories-wide .search-box input:focus {
    border: 1px solid #ceefeb;
    background-color: #fafafa;
    color: #222
}

.catagories-wide .catagories-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.catagories-wide .catagories-wrap .nav {
    position: sticky;
    top: 15px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 32%;
    flex: 0 0 32%;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
    background-color: #edf8f8;
    margin-left: -15px
}

[dir="rtl"] .catagories-wide .catagories-wrap .nav {
    margin-right: -15px;
    margin-left: unset
}

.catagories-wide .catagories-wrap .nav .nav-link {
    width: 100%;
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) 15px
}

.catagories-wide .catagories-wrap .nav .nav-link:not(:last-of-type) {
    border-bottom: 1px solid rgba(206, 239, 235, 0.5)
}

.catagories-wide .catagories-wrap .nav .nav-link .content {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)))
}

.catagories-wide .catagories-wrap .nav .nav-link .content img {
    width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
    height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
    -o-object-fit: contain;
    object-fit: contain
}

.catagories-wide .catagories-wrap .nav .nav-link .content span {
    font-weight: 600;
    line-height: 20px;
    margin-top: 3px;
    letter-spacing: 0.02em
}

@media only screen and (max-width: 360px) {
    .catagories-wide .catagories-wrap .nav .nav-link .content span {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical
    }
}

.catagories-wide .catagories-wrap .nav .nav-link.active {
    background-color: #fafafa
}

[class="dark"] .catagories-wide .catagories-wrap .nav .nav-link.active {
    background-color: rgba(255, 255, 255, 0.45)
}

.catagories-wide .catagories-wrap .tab-content {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.catagories-wide .catagories-wrap .tab-content .banner-box {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px
}

[dir="rtl"] .catagories-wide .catagories-wrap .tab-content .banner-box {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1)
}

.catagories-wide .catagories-wrap .tab-content .banner-box .content-box {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    left: 0;
    right: 0
}

[dir="rtl"] .catagories-wide .catagories-wrap .tab-content .banner-box .content-box {
    -webkit-transform: translateY(-50%) scaleX(-1);
    transform: translateY(-50%) scaleX(-1)
}

.catagories-wide .catagories-wrap .tab-content .banner-box .content-box .heading {
    font-family: "Quicksand", "sans-serif";
    font-weight: bold;
    margin-top: -5px;
    margin-bottom: -3px;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(22px + (33 - 22) * ((100vw - 320px) / (1920 - 320)))
}

[class="dark"] .catagories-wide .catagories-wrap .tab-content .banner-box .content-box .heading {
    color: #222
}

.catagories-wide .catagories-wrap .tab-content .banner-box .content-box p {
    font-family: "Quicksand", "sans-serif";
    margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    margin-top: calc(5px + (0 - 5) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
    display: block
}

[class="dark"] .catagories-wide .catagories-wrap .tab-content .banner-box .content-box p {
    color: #777
}

.catagories-wide .catagories-wrap .tab-content .banner-box .content-box .btn-solid {
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320))) calc(12px + (24 - 12) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)))
}

[class="dark"] .catagories-wide .catagories-wrap .tab-content .banner-box .content-box .btn-solid {
    border-color: #0e9146
}

.catagories-wide .catagories-wrap .tab-content .staples-box {
    text-align: center
}

.catagories-wide .catagories-wrap .tab-content .staples-box>div {
    background-color: #fafafa;
    border-radius: 6px;
    padding: calc(5px + (20 - 5) * ((100vw - 320px) / (1920 - 320)))
}

.catagories-wide .catagories-wrap .tab-content .staples-box>div img {
    width: 100%
}

@media only screen and (max-width: 375px) {
    .catagories-wide .catagories-wrap .tab-content .staples-box>div img {
        width: 80%
    }
}

.catagories-wide .catagories-wrap .tab-content .staples-box h4 {
    margin-top: 4px;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #777;
    font-weight: 600;
    -webkit-transition: all 0.5s;
    transition: all 0.5s
}

.catagories-wide .catagories-wrap .tab-content .staples-box:hover h4 {
    color: #0e9146
}

@media only screen and (max-width: 375px) {
    .catagories-wide .catagories-wrap .tab-content .row>div {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }
}

.catagories-classic .search-box {
    position: relative
}

.catagories-classic .search-box input[type="search" i]::-webkit-search-cancel-button {
    display: none !important
}

.catagories-classic .search-box i {
    position: absolute;
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    top: 50%;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.catagories-classic .search-box .search {
    left: 16px;
    font-size: 21px
}

.catagories-classic .search-box .mic {
    right: 16px;
    font-size: 23px
}

.catagories-classic .search-box input {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: 10px 47px;
    border-radius: 4px;
    color: #222;
    border: 1px solid #ceefeb;
    background-color: #fafafa
}

.catagories-classic .search-box input::-webkit-input-placeholder {
    color: #cacaca
}

.catagories-classic .search-box input::-moz-placeholder {
    color: #cacaca
}

.catagories-classic .search-box input:-ms-input-placeholder {
    color: #cacaca
}

.catagories-classic .search-box input::-ms-input-placeholder {
    color: #cacaca
}

.catagories-classic .search-box input::placeholder {
    color: #cacaca
}

.catagories-classic .search-box input:focus {
    border: 1px solid #ceefeb;
    background-color: #fafafa;
    color: #222
}

.catagories-classic .catagories-wrap .media {
    position: relative;
    background-color: #fafafa;
    border-radius: 8px
}

.catagories-classic .catagories-wrap .media a img {
    width: 100%;
    height: auto
}

@media only screen and (max-width: 375px) {
    .catagories-classic .catagories-wrap .media {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.catagories-classic .catagories-wrap .media .media-body {
    width: calc(100% - 80px)
}

.catagories-classic .catagories-wrap .media .media-body a {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 2px
}

@media only screen and (max-width: 360px) {
    .catagories-classic .catagories-wrap .media .media-body a {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical
    }
}

.catagories-classic .catagories-wrap .media .media-body p {
    margin-bottom: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.catagories-classic .catagories-wrap .media .arrow-nav {
    display: inline-block;
    margin-left: auto;
    margin-right: calc(-0px + (-10 + 0) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .catagories-classic .catagories-wrap .media .arrow-nav {
    margin-left: calc(-0px + (-10 + 0) * ((100vw - 320px) / (1920 - 320)));
    margin-right: auto;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.catagories-classic .catagories-wrap .media .arrow-nav svg {
    width: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
    height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
    stroke: #0e9146
}

.catagories-morden .search-box {
    position: relative
}

.catagories-morden .search-box input[type="search" i]::-webkit-search-cancel-button {
    display: none !important
}

.catagories-morden .search-box i {
    position: absolute;
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    top: 50%;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.catagories-morden .search-box .search {
    left: 16px;
    font-size: 21px
}

.catagories-morden .search-box .mic {
    right: 16px;
    font-size: 23px
}

.catagories-morden .search-box input {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: 10px 47px;
    border-radius: 4px;
    color: #222;
    border: 1px solid #ceefeb;
    background-color: #fafafa
}

.catagories-morden .search-box input::-webkit-input-placeholder {
    color: #cacaca
}

.catagories-morden .search-box input::-moz-placeholder {
    color: #cacaca
}

.catagories-morden .search-box input:-ms-input-placeholder {
    color: #cacaca
}

.catagories-morden .search-box input::-ms-input-placeholder {
    color: #cacaca
}

.catagories-morden .search-box input::placeholder {
    color: #cacaca
}

.catagories-morden .search-box input:focus {
    border: 1px solid #ceefeb;
    background-color: #fafafa;
    color: #222
}

.catagories-morden .catagories-wrap .catagories-sidebar {
    max-width: 250px
}

.catagories-morden .catagories-wrap .catagories-content {
    max-width: calc(100% - 250px)
}

@media only screen and (max-width: 475px) {
    .catagories-morden .catagories-wrap .catagories-content {
        max-width: 100%
    }
}

.catagories-morden .catagories-wrap .list-group {
    position: sticky;
    top: 15px;
    padding: 30px 20px;
    border-radius: 15px;
    background-color: #edf8f8
}

.catagories-morden .catagories-wrap .list-group h2 {
    font-weight: 600;
    margin-bottom: 15px
}

.catagories-morden .catagories-wrap .list-group-item {
    border: none;
    background-color: transparent;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding: 8px 0;
    color: #777
}

.catagories-morden .catagories-wrap .list-group-item.active {
    color: #0e9146
}

.catagories-morden .catagories-wrap .list-group-item:last-child {
    padding-bottom: 0
}

.catagories-morden .catagories-wrap .content:not(:first-of-type) {
    padding-top: 35px
}

.catagories-morden .catagories-wrap .content>h3 {
    margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    font-size: 16px
}

.catagories-morden .catagories-wrap .content .staples-box {
    text-align: center
}

.catagories-morden .catagories-wrap .content .staples-box>div {
    background-color: #fafafa;
    border-radius: 6px;
    padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.catagories-morden .catagories-wrap .content .staples-box>div img {
    width: 100%
}

.catagories-morden .catagories-wrap .content .staples-box h4 {
    margin-top: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
    font-size: 15px;
    color: #222;
    font-weight: 400;
    -webkit-transition: all 0.5s;
    transition: all 0.5s
}

.catagories-morden .catagories-wrap .content .staples-box:hover h4 {
    color: #0e9146
}

@media only screen and (max-width: 475px) {
    .catagories-morden .catagories-wrap>.row>div {
        width: 100%
    }

    .catagories-morden .catagories-wrap>.row>div:first-of-type {
        position: fixed;
        background-color: #fff;
        border-radius: 15px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        min-width: 200px;
        max-width: 188px;
        height: 163px;
        right: 15px;
        bottom: 76px;
        overflow-y: scroll;
        opacity: 0;
        visibility: hidden;
        z-index: 6;
        padding: 8px 22px
    }

    [dir="rtl"] .catagories-morden .catagories-wrap>.row>div:first-of-type {
        left: 15px;
        right: unset
    }

    .catagories-morden .catagories-wrap>.row>div:first-of-type .list-group {
        position: relative;
        background-color: transparent;
        padding: 0;
        top: 0
    }

    .catagories-morden .catagories-wrap>.row>div:first-of-type .list-group h2 {
        display: none
    }

    .catagories-morden .catagories-wrap>.row>div:first-of-type.show {
        opacity: 1;
        visibility: visible
    }

    .catagories-morden .catagories-wrap>.row>div:last-of-type .content .row>div {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333%
    }
}

.toggle .catagories-menu {
    z-index: 5;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background-color: #0e9146;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: fixed;
    bottom: 76px;
    right: 15px;
    font-size: 12px;
    display: none;
    color: #fff
}

[class="dark"] .toggle .catagories-menu {
    color: #f0f0f0
}

[dir="rtl"] .toggle .catagories-menu {
    left: 15px;
    right: unset
}

@media only screen and (max-width: 475px) {
    .toggle .catagories-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.toggle .catagories-menu svg {
    width: 20px;
    height: 20px;
    stroke: #fff
}

[class="dark"] .toggle .catagories-menu svg {
    stroke: #f0f0f0
}

.toggle .overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(34, 34, 34, 0.8);
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

[class="dark"] .toggle .overlay {
    background: rgba(34, 34, 34, 0.7)
}

.toggle .overlay.show {
    visibility: visible;
    opacity: 1
}

.address1-page {
    padding: 0
}

.address1-page .map-wrap {
    position: relative
}

.address1-page .map-wrap .top-address {
    padding: 8px calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    z-index: 2;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    left: 20px
}

@media (max-width: 375px) {
    .address1-page .map-wrap .top-address {
        width: 90%
    }
}

.address1-page .map-wrap .top-address svg {
    width: 20px;
    height: 20px
}

.address1-page .map-wrap .top-address p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500
}

.address1-page .map-wrap .map-section {
    height: 50vh;
    width: 100%
}

.address1-page .map-wrap .map-section canvas {
    width: 100% !important
}

.address1-page .map-wrap .map-section .H_imprint {
    display: none
}

.address1-page .map-wrap .navgator {
    padding: 8px;
    display: block;
    background-color: #fff;
    position: absolute;
    right: 20px;
    bottom: 25px;
    border-radius: 100px;
    cursor: pointer
}

[dir="rtl"] .address1-page .map-wrap .navgator {
    left: 20px;
    right: unset
}

.address1-page .map-wrap .navgator svg {
    width: 22px;
    height: 22px;
    stroke: #777
}

.address1-page .location-section {
    border-radius: 20px 20px 0px 0px;
    background-color: #fff;
    margin-top: -15px;
    position: relative;
    z-index: 2;
    padding: 18px 15px 15px 15px
}

.address1-page .location-section .search-box {
    position: relative
}

.address1-page .location-section .search-box input[type="search" i]::-webkit-search-cancel-button {
    display: none !important
}

.address1-page .location-section .search-box i {
    position: absolute;
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    top: 50%;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.address1-page .location-section .search-box .search {
    left: 16px;
    font-size: 21px
}

.address1-page .location-section .search-box .mic {
    right: 16px;
    font-size: 23px
}

.address1-page .location-section .search-box input {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: 10px 47px;
    border-radius: 4px;
    color: #222;
    border: 1px solid #ceefeb;
    background-color: #fafafa
}

.address1-page .location-section .search-box input::-webkit-input-placeholder {
    color: #cacaca
}

.address1-page .location-section .search-box input::-moz-placeholder {
    color: #cacaca
}

.address1-page .location-section .search-box input:-ms-input-placeholder {
    color: #cacaca
}

.address1-page .location-section .search-box input::-ms-input-placeholder {
    color: #cacaca
}

.address1-page .location-section .search-box input::placeholder {
    color: #cacaca
}

.address1-page .location-section .search-box input:focus {
    border: 1px solid #ceefeb;
    background-color: #fafafa;
    color: #222
}

.address1-page .location-section .current-box {
    margin-top: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.address1-page .location-section .current-box .media span {
    padding: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 4px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #0e9146
}

.address1-page .location-section .current-box .media span svg {
    width: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    height: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    stroke: #fff
}

[class="dark"] .address1-page .location-section .current-box .media span svg {
    stroke: #f0f0f0
}

.address1-page .location-section .current-box .media span svg line {
    opacity: 0.5
}

.address1-page .location-section .current-box .media .media-body h2 {
    font-weight: 600
}

.address1-page .location-section .current-box .location {
    margin-top: 7px
}

.address1-page .location-section .current-box .location .location-box {
    padding: 18px 0
}

.address1-page .location-section .current-box .location .location-box:not(:last-of-type) {
    border-bottom: 1px solid #f1f1f1
}

.address1-page .location-section .current-box .location .location-box h3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 6px
}

.address1-page .location-section .current-box .location .location-box h3 i {
    font-size: 24px
}

.address1-page .location-section .current-box .location .location-box p {
    margin-top: 7px;
    margin-bottom: 0
}

.address1-page .location-section .btn-solid {
    display: block;
    text-align: center;
    margin-top: 15px
}

.address2-page .address-wrap .address-box {
    cursor: pointer;
    padding: 14px;
    background-color: #fafafa;
    border-radius: 15px;
    border: 2px solid transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    margin-top: calc(17px + (22 - 17) * ((100vw - 320px) / (1920 - 320)))
}

.address2-page .address-wrap .address-box .conten-box .heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px
}

.address2-page .address-wrap .address-box .conten-box .heading h2 {
    font-weight: 700
}

.address2-page .address-wrap .address-box .conten-box .heading i {
    font-size: calc(22px + (24 - 22) * ((100vw - 320px) / (1920 - 320)))
}

.address2-page .address-wrap .address-box .conten-box h3 {
    margin-top: 14px;
    line-height: 18px;
    margin-bottom: 4px;
    font-weight: 500
}

.address2-page .address-wrap .address-box .conten-box p {
    margin-bottom: 0;
    line-height: 18px;
    font-weight: normal
}

.address2-page .address-wrap .address-box img {
    width: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
    height: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 15px;
    margin-left: 10px
}

[dir="rtl"] .address2-page .address-wrap .address-box img {
    margin-right: 10px;
    margin-left: unset
}

.address2-page .address-wrap .address-box.active {
    border-color: #0e9146
}

.payment-page .payment-section .accordion .accordion-item:first-of-type .accordion-header {
    border-top: none
}

.payment-page .payment-section .accordion .accordion-item .accordion-header {
    padding: 15px 0
}

.payment-page .payment-section .accordion .accordion-item .accordion-button {
    font-weight: 600
}

.payment-page .payment-section .accordion .accordion-item .accordion-button::after {
    background-color: #edf8f8;
    width: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    height: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 100%;
    background-position: center
}

[class="dark"] .payment-page .payment-section .accordion .accordion-item .accordion-button::after {
    background-color: #f0f0f0;
    -webkit-filter: invert(1);
    filter: invert(1)
}

.payment-page .payment-section .accordion .accordion-item .accordion-body {
    margin-top: 0;
    padding-bottom: 20px
}

.payment-page .payment-section .accordion .accordion-item .accordion-body.net-banking .row>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media only screen and (max-width: 567px) {
    .payment-page .payment-section .accordion .accordion-item .accordion-body.net-banking .row>div {
        width: 100%
    }
}

.payment-page .payment-section .accordion .accordion-item .accordion-body .filter-row .filter-col {
    border: 1px solid #f1f1f1;
    background-color: #fafafa;
    padding: 11px 11px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    font-weight: 600;
    color: #222;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px
}

.payment-page .payment-section .accordion .accordion-item .accordion-body .filter-row .filter-col:not(:first-of-type) {
    margin-top: 14px
}

.payment-page .payment-section .accordion .accordion-item .accordion-body .filter-row .filter-col .check {
    padding: 2px 8px 4px;
    border-radius: 0px 5px 0px 5px;
    background-color: #0e9146;
    -webkit-transform: scale(0);
    transform: scale(0);
    position: absolute;
    right: -4px;
    top: -3px
}

[dir="rtl"] .payment-page .payment-section .accordion .accordion-item .accordion-body .filter-row .filter-col .check {
    left: -4px;
    right: unset;
    border-radius: 5px 0px 5px 0px
}

.payment-page .payment-section .accordion .accordion-item .accordion-body .filter-row .filter-col .check img {
    width: 15px;
    height: 15px
}

.payment-page .payment-section .accordion .accordion-item .accordion-body .filter-row .filter-col .payment-card {
    width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
    height: auto
}

[class="dark"] .payment-page .payment-section .accordion .accordion-item .accordion-body .filter-row .filter-col .discovery {
    display: none
}

.payment-page .payment-section .accordion .accordion-item .accordion-body .filter-row .filter-col .discovery-w {
    display: none
}

[class="dark"] .payment-page .payment-section .accordion .accordion-item .accordion-body .filter-row .filter-col .discovery-w {
    display: block
}

.payment-page .payment-section .accordion .accordion-item .accordion-body .filter-row .filter-col.active {
    border-color: #0e9146
}

.payment-page .payment-section .accordion .accordion-item .accordion-body .filter-row .filter-col.active .check {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.payment-page .payment-section .accordion .accordion-item .accordion-body .input-box {
    margin-bottom: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)))
}

.payment-page .payment-section .accordion .accordion-item .accordion-body .input-box label {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.payment-page .payment-section .accordion .accordion-item .accordion-body.cash .filter-row .filter-col {
    font-weight: normal !important
}

.payment-page .order-detail {
    background-color: #fafafa;
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 18px
}

.payment-page .order-detail ul li {
    padding: 2px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.payment-page .order-detail ul li span {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    border: none;
    color: #777
}

.payment-page .order-detail ul li:last-child {
    padding: 10px 0;
    font-weight: 600;
    margin-top: 8px;
    border-top: 1px solid #f1f1f1;
    margin-bottom: -5px
}

.payment-page .order-detail ul li:last-child span {
    color: #222
}

.payment-page .order-detail h3 {
    margin-bottom: 6px
}

.payment-page .order-detail ul li:last-of-type {
    padding-bottom: 0
}

.order-success-page .banner-section .banner-wrap {
    text-align: center;
    margin-top: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)))
}

.order-success-page .banner-section .banner-wrap img {
    width: 80%
}

.order-success-page .banner-section .content-wrap {
    text-align: center;
    margin-top: 17px
}

.order-success-page .banner-section .content-wrap h1 {
    text-transform: unset;
    font-weight: 700;
    font-family: "Quicksand", "sans-serif"
}

.order-success-page .banner-section .content-wrap p {
    margin-bottom: 0;
    font-weight: normal
}

.order-success-page .order-id-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top: 1px solid #f1f1f1;
    gap: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.order-success-page .order-id-section .media>span {
    background-color: #0e9146;
    padding: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 4px
}

.order-success-page .order-id-section .media>span i {
    font-size: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
    color: #fff
}

[class="dark"] .order-success-page .order-id-section .media>span i {
    color: #f0f0f0
}

.order-success-page .order-id-section .media .media-body h2 {
    font-weight: 700;
    margin-bottom: calc(-5px + (-0 + 5) * ((100vw - 320px) / (1920 - 320)))
}

.order-success-page .order-id-section .media .media-body span {
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.order-success-page .order-detail {
    background-color: #fafafa;
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 18px
}

.order-success-page .order-detail ul li {
    padding: 2px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.order-success-page .order-detail ul li span {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    border: none;
    color: #777
}

.order-success-page .order-detail ul li:last-child {
    padding: 10px 0;
    font-weight: 600;
    margin-top: 8px;
    border-top: 1px solid #f1f1f1;
    margin-bottom: -5px
}

.order-success-page .order-detail ul li:last-child span {
    color: #222
}

.order-success-page .order-detail h3 {
    margin-bottom: 6px;
    font-weight: 700
}

.order-success-page .order-detail ul li:last-of-type {
    padding-bottom: 0
}

.order-success-page .order-detail ul li:last-of-type span:last-of-type {
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.error-404 .banner-box,
.empty-cart .banner-box,
.no-order .banner-box {
    width: 85%;
    margin: 0 auto;
    margin-top: calc(70px + (90 - 70) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    position: relative
}

.error-404 .banner-box img,
.empty-cart .banner-box img,
.no-order .banner-box img {
    width: 70%;
    position: relative
}

@media (max-width: 800px) {

    .error-404 .banner-box img,
    .empty-cart .banner-box img,
    .no-order .banner-box img {
        width: 80%
    }
}

.error-404 .error,
.empty-cart .error,
.no-order .error {
    text-align: center
}

.error-404 .error h2,
.empty-cart .error h2,
.no-order .error h2 {
    font-weight: 600;
    position: relative;
    margin-bottom: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
    font-family: "Quicksand", "sans-serif"
}

.error-404 .error h2::before,
.empty-cart .error h2::before,
.no-order .error h2::before {
    content: "";
    width: 60px;
    bottom: calc(-11px + (-15 + 11) * ((100vw - 320px) / (1920 - 320)));
    height: 2px;
    display: block;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #0e9146
}

.error-404 .error p,
.empty-cart .error p,
.no-order .error p {
    margin-bottom: calc(19px + (25 - 19) * ((100vw - 320px) / (1920 - 320)))
}

.error-404 .error .btn-solid,
.empty-cart .error .btn-solid,
.no-order .error .btn-solid {
    padding-top: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)))
}

.empty-cart .banner-box,
.no-order .banner-box {
    margin-top: 25px
}

.help-page .search-box {
    position: relative
}

.help-page .search-box input[type="search" i]::-webkit-search-cancel-button {
    display: none !important
}

.help-page .search-box i {
    position: absolute;
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    top: 50%;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.help-page .search-box .search {
    left: 16px;
    font-size: 21px
}

.help-page .search-box .mic {
    right: 16px;
    font-size: 23px
}

.help-page .search-box input {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: 10px 47px;
    border-radius: 4px;
    color: #222;
    border: 1px solid #ceefeb;
    background-color: #fafafa
}

.help-page .search-box input::-webkit-input-placeholder {
    color: #cacaca
}

.help-page .search-box input::-moz-placeholder {
    color: #cacaca
}

.help-page .search-box input:-ms-input-placeholder {
    color: #cacaca
}

.help-page .search-box input::-ms-input-placeholder {
    color: #cacaca
}

.help-page .search-box input::placeholder {
    color: #cacaca
}

.help-page .search-box input:focus {
    border: 1px solid #ceefeb;
    background-color: #fafafa;
    color: #222
}

.help-page .banner {
    text-align: center
}

.help-page .banner img {
    width: 50%
}

.help-page .questions-section h1 {
    margin-bottom: 10px;
    font-weight: 700;
    margin-top: -6px;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))) !important
}

.help-page .questions-section .accordion .accordion-item {
    background-color: #fff
}

.help-page .questions-section .accordion .accordion-item .accordion-header {
    padding: 14px 0
}

.help-page .questions-section .accordion .accordion-item .accordion-body {
    padding: 0 0 14px 0;
    margin-top: -5px
}

.search-box {
    position: relative
}

.search-box input[type="search" i]::-webkit-search-cancel-button {
    display: none !important
}

.search-box i {
    position: absolute;
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    top: 50%;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.search-box .search {
    left: 16px;
    font-size: 21px
}

.search-box .mic {
    right: 16px;
    font-size: 23px
}

.search-box input {
    font-size: calc(14px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: 7px 45px;
    border-radius: 50px;
    color: #222;
    border: 1px solid #ceefeb;
    background-color: #fafafa
}

.search-box input::-webkit-input-placeholder {
    color: #cacaca
}

.search-box input::-moz-placeholder {
    color: #cacaca
}

.search-box input:-ms-input-placeholder {
    color: #cacaca
}

.search-box input::-ms-input-placeholder {
    color: #cacaca
}

.search-box input::placeholder {
    color: #cacaca
}

.search-box input:focus {
    border: 1px solid #ceefeb;
    background-color: #fafafa;
    color: #222
}

[dir="rtl"] .banner-section .h-banner-slider {
    direction: ltr
}

.banner-section .bg-size:before {
    padding-top: 57%;
}

.banner-section .banner-box {
    overflow: hidden;
    position: relative
}

[dir="rtl"] .banner-section .banner-box {
    -webkit-transform: scale(-1);
    transform: scale(-1)
}

.banner-section .banner-box .content-box {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 18px
}

[dir="rtl"] .banner-section .banner-box .content-box {
    -webkit-transform: translateY(-50%) scale(-1);
    transform: translateY(-50%) scale(-1);
    text-align: right
}

.banner-section .banner-box .content-box .heading {
    font-family: "Quicksand", "sans-serif";
    font-weight: bold;
    margin-top: -4px;
    line-height: 25px;
    margin-bottom: -3px
}

[class="dark"] .banner-section .banner-box .content-box .heading {
    color: #222
}

[class="dark"] .banner-section .banner-box .content-box .heading.font-white {
    color: #f0f0f0
}

.banner-section .banner-box .content-box p {
    margin-bottom: 10px;
    display: block
}

[class="dark"] .banner-section .banner-box .content-box p {
    color: #777
}

[class="dark"] .banner-section .banner-box .content-box p.font-white {
    color: #f0f0f0
}

.banner-section .banner-box .content-box .btn-solid,
.banner-section .banner-box .content-box .btn-outline {
    font-weight: bold;
    padding: calc(7px + (12 - 7) * ((100vw - 320px) / (1920 - 320))) calc(24px + (34 - 24) * ((100vw - 320px) / (1920 - 320)))
}

[class="dark"] .banner-section .banner-box .content-box .btn-solid,
[class="dark"] .banner-section .banner-box .content-box .btn-outline {
    color: #f0f0f0;
    border-color: #0e9146
}

[class="dark"] .banner-section .banner-box .content-box .btn-outline {
    color: #0e9146;
    background-color: #f0f0f0;
    border-color: #0e9146
}

.banner-section .slick-track {
    padding: 1px 0;
    margin-left: -62px
}

@media only screen and (max-width: 475px) {
    .banner-section .slick-track {
        margin-left: -32px
    }
}

@media only screen and (max-width: 375px) {
    .banner-section .slick-track {
        margin-left: -15px
    }
}

@media only screen and (max-width: 375px) {
    .banner-section .slick-list {
        margin-left: -7px;
        margin-right: -7px
    }
}

.banner-section .slick-slide {
    padding: 0 12px !important
}

@media only screen and (max-width: 375px) {
    .banner-section .slick-slide {
        padding: 0 7px !important
    }
}

.recently .recently-wrap {
    position: relative;
    background-color: #edf8f8;
    padding: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 10px calc(37px + (65 - 37) * ((100vw - 320px) / (1920 - 320))) 10px 10px
}

[dir="rtl"] .recently .recently-wrap {
    border-radius: calc(37px + (65 - 37) * ((100vw - 320px) / (1920 - 320))) 10px 10px 10px
}

.recently .recently-wrap .corner {
    width: calc(25px + (45 - 25) * ((100vw - 320px) / (1920 - 320)));
    height: calc(25px + (45 - 25) * ((100vw - 320px) / (1920 - 320)));
    position: absolute;
    top: 0;
    right: 0
}

[dir="rtl"] .recently .recently-wrap .corner {
    right: unset;
    left: 0;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(180deg) scaleY(-1);
    transform: rotate(180deg) scaleY(-1)
}

.recently .recently-wrap h3 {
    font-weight: 700;
    line-height: 22px;
    margin-top: -2px
}

.recently .recently-wrap .recently-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

@media only screen and (max-width: 800px) {
    .recently .recently-wrap .recently-list {
        overflow-x: auto
    }

    .recently .recently-wrap .recently-list::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important
    }
}

.recently .recently-wrap .recently-list .slick-track {
    margin-left: -30px
}

.recently .recently-wrap .recently-list .slick-track .slick-slide {
    text-align: center
}

.recently .recently-wrap .recently-list .item {
    background-color: #fff;
    padding: 12px;
    display: inline-block;
    border-radius: 15px
}

.recently .recently-wrap .recently-list .item img {
    width: calc(50px + (80 - 50) * ((100vw - 320px) / (1920 - 320)));
    height: calc(50px + (80 - 50) * ((100vw - 320px) / (1920 - 320)))
}

.category .gap-y {
    --bs-gutter-y: 44px
}

@media only screen and (max-width: 475px) {
    .category .row>div {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333%
    }
}

.category h3 {
    text-align: center;
    font-weight: 700;
    line-height: 22px;
    display: block;
    margin-top: -2px;
    position: relative;
    z-index: 2;
    margin-bottom: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)))
}

.category h3 span:first-of-type {
    padding: 0 10px;
    background-color: #fff
}

.category h3 .line {
    position: absolute;
    width: 100%;
    background-color: #f1f1f1;
    height: 1px;
    left: 50%;
    top: 50%;
    z-index: -1;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.category .category-wrap {
    text-align: center;
    position: relative
}

.category .category-wrap .bg-shape {
    top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    position: absolute;
    border-radius: calc(15px + (23 - 15) * ((100vw - 320px) / (1920 - 320)));
    width: 100%;
    height: calc(65px + (80 - 65) * ((100vw - 320px) / (1920 - 320)))
}

.category .category-wrap a {
    position: relative;
    z-index: 2
}

.category .category-wrap a .category {
    height: calc(80px + (90 - 80) * ((100vw - 320px) / (1920 - 320)));
    width: 120px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: bottom;
    object-position: bottom
}

.category .category-wrap span {
    display: block;
    margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(12px + (17 - 14) * ((100vw - 320px) / (1920 - 320))) !important;
    font-weight: 600
}

@media only screen and (max-width: 475px) {
    .category .category-wrap span {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.offer-section .offer {
    margin-left: -15px;
    margin-right: -15px;
    background-color: #edf8f8;
    padding: calc(26px + (32 - 26) * ((100vw - 320px) / (1920 - 320))) 15px
}

.offer-section .top-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.offer-section .top-content h4 {
    font-weight: 700;
    margin-top: -6px;
    margin-bottom: calc(0px + (3 - 0) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.offer-section .top-content p {
    margin-bottom: 0
}

.offer-section .top-content a {
    font-weight: 600;
    margin-top: -2px
}

.offer-section .top-content a:hover {
    color: #0e9146
}

.low-price-section .top-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.low-price-section .top-content h4 {
    font-weight: 700;
    margin-top: -6px;
    margin-bottom: calc(0px + (3 - 0) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.low-price-section .top-content p {
    margin-bottom: 0
}

.low-price-section .top-content a {
    font-weight: 600;
    margin-top: -2px
}

.low-price-section .top-content a:hover {
    color: #0e9146
}

.low-price-section .product-slider {
    margin-top: -20px;
    margin-bottom: -20px
}

[dir="rtl"] .low-price-section .product-slider {
    direction: ltr
}

.low-price-section .product-slider .slick-list {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 0 !important;
    margin-left: calc(-7px + (-10 + 7) * ((100vw - 320px) / (1920 - 320)));
    margin-right: calc(-7px + (-10 + 7) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .low-price-section .product-slider .slick-list {
    margin-left: calc(-7px + (-10 + 7) * ((100vw - 320px) / (1920 - 320)));
    margin-right: unset
}

.low-price-section .product-slider .slick-list .slick-slide {
    padding: 0 calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)))
}

.coupons-section .coupon-wrap {
    margin-left: -15px;
    margin-right: -15px;
    background-color: #edf8f8;
    padding: calc(26px + (32 - 26) * ((100vw - 320px) / (1920 - 320))) 0
}

.coupons-section .coupon-wrap .top-content {
    padding: 0 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.coupons-section .coupon-wrap .top-content h4 {
    font-weight: 700;
    margin-top: -6px;
    margin-bottom: calc(0px + (3 - 0) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.coupons-section .coupon-wrap .top-content p {
    margin-bottom: 0
}

.coupons-section .coupon-wrap .top-content a {
    font-weight: 600;
    margin-top: -2px
}

.coupons-section .coupon-wrap .top-content a:hover {
    color: #0e9146
}

.coupons-section .coupon-wrap .coupon-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow-y: hidden;
    gap: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding-left: 15px;
    padding-right: 15px
}

@media only screen and (max-width: 800px) {
    .coupons-section .coupon-wrap .coupon-box {
        overflow-x: auto
    }

    .coupons-section .coupon-wrap .coupon-box::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important
    }
}

.coupons-section .coupon-wrap .coupon-box .slick-track {
    margin-left: -40px
}

.coupons-section .coupon-wrap .coupon-box .slick-track .slick-slide {
    padding: 0 10px
}

.coupons-section .coupon-wrap .coupon-box .cupon {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    position: relative
}

.coupons-section .coupon-wrap .coupon-box .cupon .img-wrap {
    padding: 10px;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(70%, #e8e8e8), color-stop(0%, rgba(255, 255, 255, 0)));
    background-image: linear-gradient(to right, #e8e8e8 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 14px 1px;
    background-repeat: repeat-x;
    background-size: 17px 2px
}

.coupons-section .coupon-wrap .coupon-box .cupon .img-wrap img {
    width: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
    height: auto
}

.coupons-section .coupon-wrap .coupon-box .cupon .content-wrap {
    padding: 10px
}

.coupons-section .coupon-wrap .coupon-box .cupon .content-wrap h5 {
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 2px
}

[class="dark"] .coupons-section .coupon-wrap .coupon-box .cupon .content-wrap h5 {
    color: #222
}

.coupons-section .coupon-wrap .coupon-box .cupon .content-wrap span {
    display: block;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    margin-bottom: -4px
}

[class="dark"] .coupons-section .coupon-wrap .coupon-box .cupon .content-wrap span {
    color: #777
}

.question-section h5 {
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    line-height: 28px;
    color: #cacaca;
    margin-top: -4px
}

.question-section .btn-solid {
    font-weight: 600;
    margin-top: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 375px) {
    .d-xs-none {
        display: none !important
    }
}

.notification-page .nav-wrap {
    background-color: #fafafa;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 15px;
    margin-left: -15px;
    margin-right: -15px
}

.notification-page .nav-wrap .nav-tab2 {
    background-color: transparent;
    margin: 0
}

.notification-page .nav-wrap button {
    font-weight: 600
}

.notification-page .nav-wrap button img {
    width: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    height: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.notification-page .offer-wrap .offer-box {
    margin-top: 20px
}

.notification-page .offer-wrap .offer-box .media {
    position: relative
}

.notification-page .offer-wrap .offer-box .media .icon-wrap {
    width: calc(45px + (60 - 45) * ((100vw - 320px) / (1920 - 320)));
    height: calc(45px + (60 - 45) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.notification-page .offer-wrap .offer-box .media .icon-wrap i {
    font-size: 28px
}

[class="dark"] .notification-page .offer-wrap .offer-box .media .icon-wrap i {
    color: #222
}

.notification-page .offer-wrap .offer-box .media .media-body h3 {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(150px + (375 - 150) * ((100vw - 320px) / (1920 - 320)));
    overflow: hidden;
    line-height: 21px;
    font-weight: 600
}

@media only screen and (max-width: 800px) {
    .notification-page .offer-wrap .offer-box .media .media-body h3 {
        width: calc(150px + (995 - 150) * ((100vw - 320px) / (1920 - 320)))
    }
}

.notification-page .offer-wrap .offer-box .media .badges {
    position: absolute;
    top: 0;
    font-weight: 600;
    right: 0;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .notification-page .offer-wrap .offer-box .media .badges {
    right: unset;
    left: 0
}

.offer-page .search-box {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px
}

.offer-page .search-box input[type="search" i]::-webkit-search-cancel-button {
    display: none !important
}

.offer-page .search-box i {
    position: absolute;
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    top: 50%;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.offer-page .search-box .search {
    left: 16px;
    font-size: 21px
}

.offer-page .search-box .mic {
    right: 16px;
    font-size: 23px
}

.offer-page .search-box input {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: 10px 47px;
    border-radius: 4px;
    color: #222;
    border: 1px solid #ceefeb;
    background-color: #fafafa
}

.offer-page .search-box input::-webkit-input-placeholder {
    color: #cacaca
}

.offer-page .search-box input::-moz-placeholder {
    color: #cacaca
}

.offer-page .search-box input:-ms-input-placeholder {
    color: #cacaca
}

.offer-page .search-box input::-ms-input-placeholder {
    color: #cacaca
}

.offer-page .search-box input::placeholder {
    color: #cacaca
}

.offer-page .search-box input:focus {
    border: 1px solid #ceefeb;
    background-color: #fafafa;
    color: #222
}

.offer-page .search-box>div {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.offer-page .search-box .filter {
    border: none;
    outline: none;
    background-color: transparent;
    color: #0e9146;
    font-weight: 600
}

.offer-page .offer-section .coupon-ticket {
    font-family: "Quicksand", "sans-serif";
    background-color: #fafafa;
    padding: 20px calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
    position: relative
}

.offer-page .offer-section .coupon-ticket:not(:first-of-type) {
    margin-top: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.offer-page .offer-section .coupon-ticket .media {
    position: relative;
    margin-top: calc(-3px + (-7 + 3) * ((100vw - 320px) / (1920 - 320)));
    background-color: transparent;
    gap: calc(6px + (15 - 6) * ((100vw - 320px) / (1920 - 320)));
    padding: 0
}

.offer-page .offer-section .coupon-ticket .media .off {
    gap: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media only screen and (max-width: 375px) {
    .offer-page .offer-section .coupon-ticket .media .off {
        margin-bottom: 19px
    }
}

.offer-page .offer-section .coupon-ticket .media .off>span:first-of-type {
    font-size: calc(26px + (45 - 26) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    color: #0e9146
}

.offer-page .offer-section .coupon-ticket .media .off>span:last-of-type {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.offer-page .offer-section .coupon-ticket .media .off>span:last-of-type span {
    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: normal;
    color: #0e9146
}

@media only screen and (max-width: 375px) {
    .offer-page .offer-section .coupon-ticket .media .off>span:last-of-type span {
        font-size: 18px
    }
}

.offer-page .offer-section .coupon-ticket .media .off>span:last-of-type span:first-of-type {
    margin-bottom: calc(-6px + (-10 + 6) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)))
}

.offer-page .offer-section .coupon-ticket .media .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-bottom: -4px
}

@media only screen and (max-width: 375px) {
    .offer-page .offer-section .coupon-ticket .media .media-body {
        position: absolute;
        left: 0;
        bottom: 0;
        margin-bottom: 0
    }

    [dir="rtl"] .offer-page .offer-section .coupon-ticket .media .media-body {
        right: 0;
        left: unset
    }
}

.offer-page .offer-section .coupon-ticket .media .media-body h2,
.offer-page .offer-section .coupon-ticket .media .media-body span {
    font-weight: 600;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

@media only screen and (max-width: 375px) {
    .offer-page .offer-section .coupon-ticket .media .media-body span {
        display: none
    }

    .offer-page .offer-section .coupon-ticket .media .media-body span {
        font-size: 16px
    }
}

.offer-page .offer-section .coupon-ticket .code {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 6px
}

@media only screen and (max-width: 375px) {
    .offer-page .offer-section .coupon-ticket .code {
        margin-left: auto
    }

    [dir="rtl"] .offer-page .offer-section .coupon-ticket .code {
        margin-right: auto;
        margin-left: unset
    }
}

.offer-page .offer-section .coupon-ticket .code span {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.offer-page .offer-section .coupon-ticket .code a {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(3px + (5 - 3) * ((100vw - 320px) / (1920 - 320))) calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    line-height: 22px;
    background-color: #0e9146;
    color: #fff;
    border-radius: 100px
}

[class="dark"] .offer-page .offer-section .coupon-ticket .code a {
    color: #f0f0f0
}

.offer-page .offer-section .coupon-ticket .big-circle {
    position: absolute;
    top: 50%;
    right: 20%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

[dir="rtl"] .offer-page .offer-section .coupon-ticket .big-circle {
    right: unset;
    left: 20%
}

@media only screen and (max-width: 375px) {
    .offer-page .offer-section .coupon-ticket .big-circle {
        right: 26%
    }

    [dir="rtl"] .offer-page .offer-section .coupon-ticket .big-circle {
        right: unset;
        left: 26%
    }
}

.offer-page .offer-section .coupon-ticket .big-circle span {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: transparent;
    display: block;
    position: relative
}

.offer-page .offer-section .coupon-ticket .big-circle span::before,
.offer-page .offer-section .coupon-ticket .big-circle span::after {
    content: "";
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background-color: #fff;
    position: absolute
}

.offer-page .offer-section .coupon-ticket .big-circle span::before {
    top: -42px
}

.offer-page .offer-section .coupon-ticket .big-circle span::after {
    bottom: -47px
}

@media only screen and (max-width: 375px) {
    .offer-page .offer-section .coupon-ticket .big-circle span {
        width: 30px;
        height: 30px
    }

    .offer-page .offer-section .coupon-ticket .big-circle span::before {
        top: -47px
    }

    .offer-page .offer-section .coupon-ticket .big-circle span::after {
        bottom: -50px
    }
}

.offer-page .offer-section .coupon-ticket .circle-5 {
    top: 32%
}

.offer-page .offer-section .coupon-ticket .circle-5.left {
    left: -6px
}

.offer-page .offer-section .coupon-ticket .circle-5.right {
    right: -6px
}

.onboarding-page {
    text-align: center
}

.onboarding-page .banner {
    margin-left: -15px;
    margin-right: -15px
}

.onboarding-page .banner img {
    width: 100%
}

.onboarding-page .content-wrap {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px
}

.onboarding-page .content-wrap .logo {
    width: calc(130px + (150 - 130) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)))
}

[class="dark"] .onboarding-page .content-wrap .logo {
    display: none
}

.onboarding-page .content-wrap .logo-w {
    display: none
}

[class="dark"] .onboarding-page .content-wrap .logo-w {
    display: block
}

.onboarding-page .content-wrap h1,
.onboarding-page .content-wrap h2 {
    margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    font-family: "Quicksand", "sans-serif";
    margin-top: -4px;
    line-height: 25px
}

.onboarding-page .content-wrap p {
    line-height: 22px;
    margin-top: -5px;
    margin-bottom: 0
}

.onboarding-page .social-wrap a {
    display: block;
    padding: 14px;
    border-radius: 5px;
    background-color: #fafafa;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 8px
}

.onboarding-page .social-wrap a:not(:first-of-type) {
    margin-top: 15px
}

.onboarding-page .social-wrap a img {
    width: 20px;
    height: auto
}

[dir="rtl"] .onboarding-page .onboarding-slider {
    direction: ltr
}

.onboarding-page .onboarding-slider .slick-dots {
    margin-top: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)))
}

.onboarding-page .btn-solid {
    margin-bottom: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)))
}

.onboarding-page .slick-next,
.onboarding-page .slick-prev {
    display: none !important
}

.order-detail .banner-box {
    padding: 15px;
    background-color: #0e9146;
    border-radius: 7px
}

.order-detail .banner-box .media img {
    width: calc(40px + (45 - 40) * ((100vw - 320px) / (1920 - 320)));
    height: calc(40px + (45 - 40) * ((100vw - 320px) / (1920 - 320)))
}

.order-detail .banner-box .media .media-body span {
    color: #fff;
    display: block;
    margin-top: calc(-1px + (-3 + 1) * ((100vw - 320px) / (1920 - 320)))
}

[class="dark"] .order-detail .banner-box .media .media-body span {
    color: #f0f0f0
}

.order-detail .banner-box .media .media-body h2 {
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #fff;
    font-weight: 600
}

[class="dark"] .order-detail .banner-box .media .media-body h2 {
    color: #f0f0f0
}

.order-detail .item-section h3 {
    font-weight: 600;
    margin-top: -3px
}

.order-detail .item-section .item-wrap .media {
    padding: 15px
}

.order-detail .item-section .item-wrap .media:not(:first-of-type) {
    border-top: 1px solid #f1f1f1
}

.order-detail .item-section .item-wrap .media:last-of-type {
    padding-bottom: 0
}

.order-detail .item-section .item-wrap .media .count {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px
}

.order-detail .item-section .item-wrap .media .count>span {
    background-color: #0e9146;
    width: calc(26px + (32 - 26) * ((100vw - 320px) / (1920 - 320)));
    height: calc(26px + (32 - 26) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 5px
}

[class="dark"] .order-detail .item-section .item-wrap .media .count>span {
    color: #f0f0f0
}

.order-detail .item-section .item-wrap .media .count svg {
    width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    height: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.order-detail .item-section .item-wrap .media .media-body h4 {
    margin-top: -5px;
    width: 220px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

@media only screen and (max-width: 375px) {
    .order-detail .item-section .item-wrap .media .media-body h4 {
        width: 136px;
        font-weight: 600
    }
}

.order-detail .item-section .item-wrap .media .media-body span {
    display: block;
    margin-bottom: -2px
}

.order-detail .item-section .item-wrap .media>span {
    margin-left: auto
}

[dir="rtl"] .order-detail .item-section .item-wrap .media>span {
    margin-left: unset;
    margin-right: auto
}

.order-detail .order-summary {
    margin-top: 33px
}

.order-detail .order-summary ul li {
    padding: 2px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.order-detail .order-summary ul li span {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    border: none;
    color: #777
}

.order-detail .order-summary ul li:last-child {
    padding: 10px 0;
    font-weight: 600;
    margin-top: 8px;
    border-top: 1px solid #f1f1f1;
    margin-bottom: -5px
}

.order-detail .order-summary ul li:last-child span {
    color: #222
}

.order-detail .order-summary ul li:last-of-type {
    padding-bottom: 0;
    margin-bottom: -4px
}

.order-detail .order-summary ul li:last-of-type span:last-of-type {
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.order-detail .order-summary h3 {
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: -3px
}

.order-detail .address-section {
    margin-top: 33px
}

.order-detail .address-section h3 {
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: -3px
}

.order-detail .address-section .address h4 {
    font-weight: 500;
    margin-top: -1px
}

.order-detail .address-section .address p {
    margin-bottom: -4px;
    font-weight: normal
}

.order-detail .payment-method {
    margin-top: 33px
}

.order-detail .payment-method h3 {
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: -3px
}

.order-detail .payment-method .payment-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px
}

.order-detail .payment-method .payment-box img {
    width: 50px;
    height: auto
}

.order-detail .payment-method .payment-box span {
    font-weight: normal
}

.order-history .search-box {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px
}

.order-history .search-box input[type="search" i]::-webkit-search-cancel-button {
    display: none !important
}

.order-history .search-box i {
    position: absolute;
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    top: 50%;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.order-history .search-box .search {
    left: 16px;
    font-size: 21px
}

.order-history .search-box .mic {
    right: 16px;
    font-size: 23px
}

.order-history .search-box input {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: 10px 47px;
    border-radius: 4px;
    color: #222;
    border: 1px solid #ceefeb;
    background-color: #fafafa
}

.order-history .search-box input::-webkit-input-placeholder {
    color: #cacaca
}

.order-history .search-box input::-moz-placeholder {
    color: #cacaca
}

.order-history .search-box input:-ms-input-placeholder {
    color: #cacaca
}

.order-history .search-box input::-ms-input-placeholder {
    color: #cacaca
}

.order-history .search-box input::placeholder {
    color: #cacaca
}

.order-history .search-box input:focus {
    border: 1px solid #ceefeb;
    background-color: #fafafa;
    color: #222
}

.order-history .search-box>div {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.order-history .search-box .filter {
    border: none;
    outline: none;
    background-color: transparent;
    color: #0e9146;
    font-weight: 600
}

.order-history .tab-content .tab-pane .order-box {
    background-color: #eee;
    padding: 15px;
    border-radius: 10px
}

.order-history .tab-content .tab-pane .order-box:not(:first-of-type) {
    margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.order-history .tab-content .tab-pane .order-box .media {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.order-history .tab-content .tab-pane .order-box .media .content-box h2 {
    font-weight: 600;
    margin-bottom: 3px
}

.order-history .tab-content .tab-pane .order-box .media .content-box p {
    margin-bottom: 3px;
    width: calc(180px + (245 - 180) * ((100vw - 320px) / (1920 - 320)));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.order-history .tab-content .tab-pane .order-box .media .media-body img {
    width: calc(75px + (100 - 75) * ((100vw - 320px) / (1920 - 320)));
    height: calc(75px + (100 - 75) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 10px
}

.order-history .tab-content .tab-pane .order-box .bottom-content {
    margin-top: 15px;
    padding-top: 10px;
    border-top: 1px solid #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.order-history .tab-content .tab-pane .order-box .bottom-content .give-rating:hover {
    color: #777
}

.order-history .tab-content .tab-pane .order-box .bottom-content .rating {
    display: none
}

.order-history .tab-content .tab-pane .order-box .bottom-content.active .give-rating {
    display: none
}

.order-history .tab-content .tab-pane .order-box .bottom-content.active .rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.order-tracking-page {
    padding: 0
}

.order-tracking-page .map-section {
    height: 50vh;
    width: 100%
}

.order-tracking-page .map-section canvas {
    width: 100% !important
}

.order-tracking-page .map-section .H_imprint {
    display: none
}

.order-tracking-page .location-section {
    border-radius: 20px 20px 0px 0px;
    background-color: #fff;
    margin-top: -15px;
    position: relative;
    z-index: 2;
    padding: 17px 15px 15px 15px
}

.order-tracking-page .location-section .time-box {
    text-align: center;
    padding-bottom: 15px
}

.order-tracking-page .location-section .time-box span {
    display: block;
    margin-top: -3px
}

.order-tracking-page .location-section .time-box h1 {
    font-size: calc(19px + (22 - 19) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    color: #0e9146;
    margin-bottom: -4px
}

.order-tracking-page .location-section .current-box .media {
    padding-top: 18px;
    padding-bottom: 18px;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1
}

.order-tracking-page .location-section .current-box .media img {
    width: calc(46px + (55 - 46) * ((100vw - 320px) / (1920 - 320)));
    height: calc(46px + (55 - 46) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 100%
}

.order-tracking-page .location-section .current-box .action-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    margin-left: auto
}

[dir="rtl"] .order-tracking-page .location-section .current-box .action-box {
    margin-left: unset;
    margin-right: auto
}

.order-tracking-page .location-section .current-box .action-box span,
.order-tracking-page .location-section .current-box .action-box a {
    width: calc(33px + (40 - 33) * ((100vw - 320px) / (1920 - 320)));
    height: calc(33px + (40 - 33) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 4px;
    border: 1px solid #0e9146;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.order-tracking-page .location-section .current-box .action-box span i,
.order-tracking-page .location-section .current-box .action-box a i {
    font-size: calc(20px + (23 - 20) * ((100vw - 320px) / (1920 - 320)));
    color: #fff
}

[class="dark"] .order-tracking-page .location-section .current-box .action-box span i,
[class="dark"] .order-tracking-page .location-section .current-box .action-box a i {
    color: #f0f0f0
}

.order-tracking-page .location-section .tracking-box {
    padding: 25px 0
}

.order-tracking-page .location-section .tracking-box .media {
    position: relative;
    z-index: 2
}

.order-tracking-page .location-section .tracking-box .media:not(:first-of-type) {
    padding-top: 16px
}

.order-tracking-page .location-section .tracking-box .media:not(:last-of-type) {
    padding-bottom: 16px
}

.order-tracking-page .location-section .tracking-box .media:last-of-type::after {
    display: none
}

.order-tracking-page .location-section .tracking-box .media::after {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    border-left: 2px dashed #f1f1f1;
    position: absolute;
    left: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    top: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1
}

[dir="rtl"] .order-tracking-page .location-section .tracking-box .media::after {
    right: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    left: unset
}

.order-tracking-page .location-section .tracking-box .media>span {
    width: calc(36px + (40 - 36) * ((100vw - 320px) / (1920 - 320)));
    height: calc(36px + (40 - 36) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 4px;
    border: 1px solid #0e9146;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.order-tracking-page .location-section .tracking-box .media>span i {
    font-size: 25px;
    color: #fff
}

[class="dark"] .order-tracking-page .location-section .tracking-box .media>span i {
    color: #f0f0f0
}

@media only screen and (max-width: 375px) {
    .order-tracking-page .location-section .tracking-box .media-body h3 {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }
}

.order-tracking-page .location-section .tracking-box .media-body span {
    display: block;
    margin-bottom: -5px
}

.order-tracking-page .location-section .btn-solid {
    display: block;
    text-align: center;
    margin-top: 15px
}

.product-page .banner-box {
    background-color: #fafafa;
    margin-bottom: 0;
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: calc(50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .product-page .banner-box {
    direction: ltr
}

.product-page .banner-box .banner img {
    margin: 0 auto;
    width: 80%
}

.product-page .product-section {
    border-radius: 0;
    background-color: #fff;
    position: relative;
    z-index: 1;
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px 15px 0 15px
}

.product-page .product-section h1 {
    font-weight: 700;
    color: #222;
    line-height: 20px;
    margin-top: -3px
}

.product-page .product-section .rating {
    margin-top: 8px;
    margin-bottom: 4px
}

.product-page .product-section .rating span {
    margin-left: 4px
}

[dir="rtl"] .product-page .product-section .rating span {
    margin-right: 4px;
    margin-left: unset
}

.product-page .product-section .price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px
}

.product-page .product-section .price span:first-of-type {
    font-size: 18px;
    color: #222;
    font-weight: 600
}

.product-page .product-section .price span:last-of-type {
    font-size: 16px;
    color: #0e9146;
    font-weight: 600
}

.product-page .product-section .price del {
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    color: #777
}

.product-page .product-section .select-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 15px;
    margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.product-page .product-section .select-group .size-Select {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    border: 1px solid #f1f1f1;
    padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320))) calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fafafa
}

.product-page .product-section .select-group .size-Select svg {
    margin-left: auto;
    width: 20px;
    height: 20px;
    margin-right: -6px;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

[dir="rtl"] .product-page .product-section .select-group .size-Select svg {
    margin-left: -6px;
    margin-right: auto
}

.product-page .product-section .select-group .size-Select .size-box {
    text-align: center
}

.product-page .product-section .select-group .size-Select .size-box span {
    cursor: pointer
}

.product-page .product-section .product-detail .product-detail-box h2 {
    margin-top: -3px;
    margin-bottom: 3px;
    font-weight: 700;
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.product-page .product-review {
    margin-top: calc(-10px + (-18 - -10) * ((100vw - 320px) / (1920 - 320)))
}

.product-page .product-review .top-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.product-page .product-review .top-content h3 {
    font-weight: 600;
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.product-page .product-review .top-content a {
    color: #0e9146;
    font-weight: 600
}

.product-page .product-review .review-wrap .review-box {
    padding: 17px;
    margin-top: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    background-color: #fafafa
}

.product-page .product-review .review-wrap .review-box .media img {
    width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
    height: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 100%
}

.product-page .product-review .review-wrap .review-box .media .rating {
    gap: 2px
}

.product-page .product-review .review-wrap .review-box .media .rating svg {
    width: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    height: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.product-page .product-review .review-wrap .review-box .media .media-body h4 {
    font-weight: 600;
    margin-top: -4px
}

.product-page .product-review .review-wrap .review-box p {
    margin-bottom: 0;
    margin-top: 5px;
    margin-bottom: -2px
}

.product-page .check-delivery-section .title-section {
    margin-top: -3px
}

.product-page .check-delivery-section .title-section h4 {
    font-weight: 700;
    margin-top: -6px;
    margin-bottom: calc(0px + (3 - 0) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.product-page .check-delivery-section .custom-form {
    position: relative
}

.product-page .check-delivery-section .custom-form a {
    color: #0e9146;
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

[dir="rtl"] .product-page .check-delivery-section .custom-form a {
    right: unset;
    left: 10px
}

.product-page .check-delivery-section .service-section {
    margin-top: 18px;
    margin-bottom: -2px
}

.product-page .check-delivery-section .service-section ul li {
    color: #777;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.product-page .check-delivery-section .service-section ul li img {
    margin-right: 10px
}

[class="dark"] .product-page .check-delivery-section .service-section ul li img {
    -webkit-filter: invert(1);
    filter: invert(1)
}

[dir="rtl"] .product-page .check-delivery-section .service-section ul li img {
    margin-right: 0;
    margin-left: 10px
}

.product-page .check-delivery-section .service-section ul li+li {
    margin-top: 8px
}

.product-page .recently-viewed {
    background-color: #edf8f8;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -15px;
    margin-right: -15px
}

.product-page .recently-viewed .product-slider {
    margin-top: -20px;
    margin-bottom: -20px
}

[dir="rtl"] .product-page .recently-viewed .product-slider {
    direction: ltr
}

.product-page .recently-viewed .product-slider .slick-list {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 0 !important;
    margin-left: calc(-7px + (-10 + 7) * ((100vw - 320px) / (1920 - 320)));
    margin-right: calc(-7px + (-10 + 7) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .product-page .recently-viewed .product-slider .slick-list {
    margin-left: calc(-7px + (-10 + 7) * ((100vw - 320px) / (1920 - 320)));
    margin-right: unset
}

.product-page .recently-viewed .product-slider .slick-list .slick-slide {
    padding: 0 calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)))
}

.product-page .recently-viewed .top-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.product-page .recently-viewed .top-content h4 {
    font-weight: 700;
    margin-top: -6px;
    margin-bottom: calc(0px + (3 - 0) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.product-page .recently-viewed .top-content p {
    margin-bottom: 0
}

.product-page .recently-viewed .top-content a {
    font-weight: 600;
    margin-top: -2px
}

.product-page .recently-viewed .top-content a:hover {
    color: #0e9146
}

.product-page .recently-viewed .top-content>a {
    font-weight: 600
}

.product-page .recently-viewed .product-recent-slider {
    margin-top: -20px;
    margin-bottom: -20px
}

[dir="rtl"] .product-page .recently-viewed .product-recent-slider {
    direction: ltr
}

.product-page .recently-viewed .product-recent-slider .slick-list {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 0 !important;
    margin-left: calc(-7px + (-10 + 7) * ((100vw - 320px) / (1920 - 320)));
    margin-right: calc(-7px + (-10 + 7) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .product-page .recently-viewed .product-recent-slider .slick-list {
    margin-left: calc(-7px + (-10 + 7) * ((100vw - 320px) / (1920 - 320)));
    margin-right: unset
}

.product-page .recently-viewed .product-recent-slider .slick-list .slick-slide {
    padding: 0 calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)))
}

.search-page .search-box {
    position: relative
}

.search-page .search-box input[type="search" i]::-webkit-search-cancel-button {
    display: none !important
}

.search-page .search-box i {
    position: absolute;
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    top: 50%;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.search-page .search-box .search {
    left: 16px;
    font-size: 21px
}

.search-page .search-box .mic {
    right: 16px;
    font-size: 23px
}

.search-page .search-box input {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: 10px 47px;
    border-radius: 4px;
    color: #222;
    border: 1px solid #ceefeb;
    background-color: #fafafa
}

.search-page .search-box input::-webkit-input-placeholder {
    color: #cacaca
}

.search-page .search-box input::-moz-placeholder {
    color: #cacaca
}

.search-page .search-box input:-ms-input-placeholder {
    color: #cacaca
}

.search-page .search-box input::-ms-input-placeholder {
    color: #cacaca
}

.search-page .search-box input::placeholder {
    color: #cacaca
}

.search-page .search-box input:focus {
    border: 1px solid #ceefeb;
    background-color: #fafafa;
    color: #222
}

.search-page .recent-search ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px
}

.search-page .recent-search ul li {
    padding: 6px 20px;
    background-color: #edf8f8;
    border-radius: 5px;
    font-weight: normal;
    cursor: pointer
}

.search-page .trending .category {
    padding: 10px;
    border-radius: 12px;
    text-align: center;
    display: block
}

.search-page .trending .category img {
    width: calc(45px + (60 - 45) * ((100vw - 320px) / (1920 - 320)));
    height: calc(45px + (60 - 45) * ((100vw - 320px) / (1920 - 320)))
}

@media only screen and (max-width: 375px) {
    .search-page .trending .category img {
        width: 60px;
        height: 60px
    }
}

@media only screen and (max-width: 375px) {
    .search-page .trending .row>div {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33%
    }
}

.search-page .trending-products .product-wrap .media {
    background-color: #fafafa
}

.setting-page .user-panel {
    margin-bottom: calc(14px + (19 - 14) * ((100vw - 320px) / (1920 - 320)));
    width: 100%;
    padding: 17px;
    border-radius: 7px
}

.setting-page .user-panel .media {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.setting-page .user-panel .media .avatar-wrap {
    position: relative
}

.setting-page .user-panel .media .avatar-wrap img {
    width: calc(80px + (120 - 80) * ((100vw - 320px) / (1920 - 320)));
    height: calc(80px + (120 - 80) * ((100vw - 320px) / (1920 - 320)));
    display: block;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.setting-page .user-panel .media .avatar-wrap .edit {
    position: absolute;
    cursor: pointer;
    width: calc(27px + (30 - 27) * ((100vw - 320px) / (1920 - 320)));
    height: calc(27px + (30 - 27) * ((100vw - 320px) / (1920 - 320)));
    background-color: #0e9146;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    right: 0;
    bottom: calc(0px + (10 - 0) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .setting-page .user-panel .media .avatar-wrap .edit {
    left: 0;
    right: unset
}

.setting-page .user-panel .media .avatar-wrap .edit svg {
    width: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    height: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    stroke: #fff
}

[class="dark"] .setting-page .user-panel .media .avatar-wrap .edit svg {
    stroke: #f0f0f0
}

.setting-page .user-panel .media .media-body {
    text-align: center
}

.setting-page .user-panel .media .media-body h2 {
    font-weight: 600;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(-3px + (-0 + 3) * ((100vw - 320px) / (1920 - 320)))
}

.setting-page .custom-form button {
    display: block;
    width: 100%;
    margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.shop-page .search-box {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
    margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.shop-page .search-box input[type="search" i]::-webkit-search-cancel-button {
    display: none !important
}

.shop-page .search-box i {
    position: absolute;
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    top: 50%;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.shop-page .search-box .search {
    left: 16px;
    font-size: 21px
}

.shop-page .search-box .mic {
    right: 16px;
    font-size: 23px
}

.shop-page .search-box input {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: 10px 47px;
    border-radius: 4px;
    color: #222;
    border: 1px solid #ceefeb;
    background-color: #fafafa
}

.shop-page .search-box input::-webkit-input-placeholder {
    color: #cacaca
}

.shop-page .search-box input::-moz-placeholder {
    color: #cacaca
}

.shop-page .search-box input:-ms-input-placeholder {
    color: #cacaca
}

.shop-page .search-box input::-ms-input-placeholder {
    color: #cacaca
}

.shop-page .search-box input::placeholder {
    color: #cacaca
}

.shop-page .search-box input:focus {
    border: 1px solid #ceefeb;
    background-color: #fafafa;
    color: #222
}

.shop-page .search-box>div {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.shop-page .search-box .filter {
    border: none;
    outline: none;
    background-color: transparent;
    color: #0e9146;
    font-weight: 600
}

.shop-page .tab-content .tab-pane .media {
    background-color: #fafafa
}

.shop-page .tab-content .tab-pane .media .media-body>a {
    font-weight: 600
}

.shop-page .tab-content .tab-pane .bg-size:before {
    padding-top: 0
}

.shop-page .tab-content .tab-pane .banner {
    position: relative;
    margin: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) -15px
}

.shop-page .tab-content .tab-pane .banner h2 {
    padding: 0 44px;
    position: absolute;
    font-weight: 600;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: "Quicksand", "sans-serif";
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)))
}

[class="dark"] .shop-page .tab-content .tab-pane .banner h2 {
    color: #222
}

.terms-condition .list-section {
    list-style: disc;
    padding: 0 18px
}

.terms-condition h1 {
    font-size: 17px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: calc(18px + (23 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.terms-condition .title-sub {
    font-size: 16px
}

.terms-condition .title-mb {
    margin-top: -6px;
    margin-bottom: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.terms-condition .list-section li {
    font-size: 14px
}

.terms-condition p {
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)))
}

@-webkit-keyframes skeletonAnimation {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        background-position: -468px 0
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        background-position: 468px 0
    }
}

@keyframes skeletonAnimation {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        background-position: -468px 0
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        background-position: 468px 0
    }
}

.sk-hed {
    width: 25%;
    height: 18px
}

.sk-1 {
    width: 90%;
    height: 15px;
    margin-top: 5px
}

.sk-2 {
    height: 15px;
    width: 50%;
    margin-top: 5px
}

.sk-3 {
    height: 15px;
    width: 25%;
    margin-top: 5px
}

.skeleton-loader {
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    height: 100%;
    z-index: 999;
    display: block;
    overflow: auto
}

@media only screen and (min-width: 800px) {
    .skeleton-loader {
        max-width: 767px;
        margin: 0 auto
    }
}

.skeleton-loader .search-box .form-control {
    border: 1px solid #f3f3f3 !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .search-box button {
    position: relative
}

.skeleton-loader .search-box button::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .h-banner-slider .banner-box {
    position: relative
}

.skeleton-loader .h-banner-slider .banner-box::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .recently-wrap {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .recently-wrap h3 {
    display: inline-block;
    position: relative
}

.skeleton-loader .recently-wrap h3::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .recently-wrap h3::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .recently-wrap .recently-list {
    overflow: hidden !important
}

.skeleton-loader .recently-wrap .recently-list .item .img {
    width: calc(50px + (80 - 50) * ((100vw - 320px) / (1920 - 320)));
    height: calc(50px + (80 - 50) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff
}

.skeleton-loader .category>h3 span:first-of-type {
    width: 192px;
    height: 26px;
    display: block;
    margin: 0 auto;
    position: relative;
    padding: 4px 14px
}

.skeleton-loader .category>h3 span:first-of-type::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .category>h3 span:first-of-type:after {
    left: 8px
}

[dir="rtl"] .skeleton-loader .category>h3 span:first-of-type:after {
    right: 8px;
    left: unset
}

.skeleton-loader .category .category-wrap .bg-shape {
    position: relative;
    top: 0
}

.skeleton-loader .category .category-wrap .bg-shape::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .category .category-wrap span {
    height: 20px;
    margin-top: 10px;
    margin-bottom: 0;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .offer-section .offer {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .offer-section .offer .top-content h4 {
    position: relative;
    display: inline-block
}

.skeleton-loader .offer-section .offer .top-content h4::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .offer-section .offer .top-content h4::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .offer-section .offer .top-content p {
    position: relative;
    margin-top: 8px
}

.skeleton-loader .offer-section .offer .top-content p::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .offer-section .offer .top-content p::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .offer-section .offer .top-content a {
    position: relative
}

.skeleton-loader .offer-section .offer .top-content a::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .offer-section .offer .top-content a::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .offer-section .offer .offer-wrap .media {
    position: relative
}

.skeleton-loader .offer-section .offer .offer-wrap .media::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .offer-section .offer .offer-wrap .media::after {
    background-image: unset;
    background-color: #e8e8e8
}

.skeleton-loader .catagories-morden .list-group {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-morden .list-group h2 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    display: inline-block;
    color: transparent;
    position: relative
}

.skeleton-loader .catagories-morden .list-group h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-morden .list-group h2::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .catagories-morden .list-group .list-group-item {
    color: transparent;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    display: inline-block;
    position: relative;
    padding: 0;
    margin: 8px 0
}

.skeleton-loader .catagories-morden .list-group .list-group-item::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-morden .list-group .list-group-item::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .catagories-morden .list-group .list-group-item.active {
    color: transparent
}

.skeleton-loader .catagories-morden .list-group .list-group-item::after {
    height: 80%
}

.skeleton-loader .catagories-morden .content h3 {
    position: relative;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    color: transparent
}

.skeleton-loader .catagories-morden .content h3::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .catagories-morden .content h3::after {
    height: 120%;
    width: 120%;
    left: -2px
}

[dir="rtl"] .skeleton-loader .catagories-morden .content h3::after {
    right: -2px;
    left: unset
}

.skeleton-loader .catagories-morden .content .staples-box>div {
    position: relative
}

.skeleton-loader .catagories-morden .content .staples-box>div::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-morden .content .staples-box>div:after {
    width: 100%;
    height: 100%
}

.skeleton-loader .catagories-morden .content .staples-box h4 {
    display: inline-block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative;
    color: transparent
}

.skeleton-loader .catagories-morden .content .staples-box h4::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .catagories-morden .content .staples-box h4:after {
    height: 60%
}

.skeleton-loader .catagories-morden .content .staples-box:hover h4 {
    color: transparent
}

.skeleton-loader .catagories-classic .catagories-wrap .media {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-classic .catagories-wrap .media .link {
    padding-right: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    border-right: 1px solid #f1f1f1
}

.skeleton-loader .catagories-classic .catagories-wrap .media .link .img {
    width: calc(58px + (70 - 58) * ((100vw - 320px) / (1920 - 320)));
    height: calc(58px + (70 - 58) * ((100vw - 320px) / (1920 - 320)));
    position: relative
}

.skeleton-loader .catagories-classic .catagories-wrap .media .link .img::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-classic .catagories-wrap .media .link .img::after {
    background-image: unset;
    background-color: #e8e8e8
}

[dir="rtl"] .skeleton-loader .catagories-classic .catagories-wrap .media .link {
    padding-right: unset;
    padding-left: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    border-right: unset;
    border-left: 1px solid #f1f1f1
}

.skeleton-loader .catagories-classic .catagories-wrap .media .media-body a {
    width: calc(110px + (200 - 110) * ((100vw - 320px) / (1920 - 320)));
    height: 18px;
    display: inline-block;
    position: relative
}

.skeleton-loader .catagories-classic .catagories-wrap .media .media-body a::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-classic .catagories-wrap .media .media-body a::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .catagories-classic .catagories-wrap .media .media-body p {
    margin-top: 3px;
    width: calc(138px + (250 - 138) * ((100vw - 320px) / (1920 - 320)));
    height: 15px;
    position: relative
}

.skeleton-loader .catagories-classic .catagories-wrap .media .media-body p::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-classic .catagories-wrap .media .media-body p::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .catagories-classic .catagories-wrap .media .svg {
    width: 24px;
    height: 24px;
    display: block;
    margin-left: auto;
    position: relative
}

[dir="rtl"] .skeleton-loader .catagories-classic .catagories-wrap .media .svg {
    margin-right: auto;
    margin-left: unset
}

.skeleton-loader .catagories-classic .catagories-wrap .media .svg::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-classic .catagories-wrap .media .svg::after {
    background-image: unset;
    background-color: #e8e8e8
}

.skeleton-loader .catagories-wide .nav {
    position: static;
    background-color: #f3f3f3
}

.skeleton-loader .catagories-wide .nav .nav-link .content .img {
    width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
    height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
    position: relative
}

.skeleton-loader .catagories-wide .nav .nav-link .content .img::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-wide .nav .nav-link .content .img::after {
    background-image: unset;
    background-color: #e8e8e8
}

.skeleton-loader .catagories-wide .nav .nav-link .content .sk-2 {
    display: none;
    margin-top: 0
}

@media only screen and (max-width: 536px) {
    .skeleton-loader .catagories-wide .nav .nav-link .content .sk-2 {
        display: block
    }
}

.skeleton-loader .catagories-wide .nav .nav-link .content span {
    position: relative
}

.skeleton-loader .catagories-wide .nav .nav-link .content span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-wide .nav .nav-link .content span::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .catagories-wide .nav .nav-link.active {
    background-color: #fff
}

.skeleton-loader .catagories-wide .nav .nav-link.active .img {
    position: relative
}

.skeleton-loader .catagories-wide .nav .nav-link.active .img::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-wide .nav .nav-link.active span {
    position: relative
}

.skeleton-loader .catagories-wide .nav .nav-link.active span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .catagories-wide .tab-content .banner-box {
    position: relative
}

.skeleton-loader .catagories-wide .tab-content .banner-box::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-wide .tab-content .staples-box>div {
    position: relative
}

.skeleton-loader .catagories-wide .tab-content .staples-box>div::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .catagories-wide .tab-content .staples-box>div:after {
    width: 100%;
    height: 100%
}

.skeleton-loader .catagories-wide .tab-content .staples-box h4 {
    display: inline-block;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative;
    color: transparent
}

.skeleton-loader .catagories-wide .tab-content .staples-box h4::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .catagories-wide .tab-content .staples-box h4:after {
    height: 60%
}

.skeleton-loader .catagories-wide .tab-content .staples-box:hover h4 {
    color: transparent
}

.skeleton-loader .shop-page .nav {
    background-color: #f3f3f3
}

.skeleton-loader .shop-page .nav .nav-item .nav-link {
    position: relative
}

.skeleton-loader .shop-page .nav .nav-item .nav-link::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .shop-page .nav .nav-item .nav-link::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .shop-page .tab-content .tab-pane .media {
    background-color: #f3f3f3
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .link {
    width: calc(70px + (90 - 70) * ((100vw - 320px) / (1920 - 320)));
    height: calc(50px + (65 - 50) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    border-right: 1px solid #f1f1f1
}

[dir="rtl"] .skeleton-loader .shop-page .tab-content .tab-pane .media .link {
    padding-right: unset;
    padding-left: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    border-left: 1px solid #f1f1f1;
    border-right: unset
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .link .img {
    width: 100%;
    height: 100%;
    background-color: #e8e8e8
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .media-body a {
    position: relative
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .media-body a::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .media-body a::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .media-body>span:first-of-type {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-top: 4px;
    position: relative
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .media-body>span:first-of-type::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .media-body>span:first-of-type::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .media-body>span:last-of-type span {
    position: relative;
    border-color: #fff
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .media-body>span:last-of-type span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .media-body>span:last-of-type span::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .media-body .plus-minus {
    width: 60px;
    height: 30px;
    position: relative;
    position: absolute
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .media-body .plus-minus::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .shop-page .tab-content .tab-pane .media .media-body .plus-minus::after {
    background-image: unset;
    background-color: #e8e8e8
}

@media only screen and (max-width: 375px) {
    .skeleton-loader .shop-page .tab-content .tab-pane .media .media-body .plus-minus {
        position: relative
    }
}

.skeleton-loader .shop-page .tab-content .tab-pane .banner {
    position: relative
}

.skeleton-loader .shop-page .tab-content .tab-pane .banner::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .product-page .banner-box {
    background-color: #f3f3f3
}

.skeleton-loader .product-page .banner-box .banner {
    position: relative
}

.skeleton-loader .product-page .banner-box .banner::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .product-page .banner-box .slick-dots li {
    position: relative;
    border-color: #fff;
    background-color: #fff
}

.skeleton-loader .product-page .banner-box .slick-dots li::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .product-page .banner-box .slick-dots li::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .product-page .banner-box .slick-dots li.slick-active {
    background-color: #fff !important
}

.skeleton-loader .product-page .product-section .hed {
    width: calc(200px + (380 - 200) * ((100vw - 320px) / (1920 - 320)));
    height: 22px;
    position: relative
}

.skeleton-loader .product-page .product-section .hed::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .product-page .product-section .rating .start {
    width: 20px;
    height: 20px;
    position: relative
}

.skeleton-loader .product-page .product-section .rating .start::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .product-page .product-section .rating .content-color {
    position: relative
}

.skeleton-loader .product-page .product-section .rating .content-color::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .product-page .product-section .price {
    margin-top: 2px
}

.skeleton-loader .product-page .product-section .price span,
.skeleton-loader .product-page .product-section .price del {
    line-height: 20px;
    position: relative
}

.skeleton-loader .product-page .product-section .price span::after,
.skeleton-loader .product-page .product-section .price del::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .product-page .product-section .select-group .size-Select {
    background-color: #f3f3f3
}

.skeleton-loader .product-page .product-section .select-group .size-Select .size-box span {
    position: relative;
    width: calc(70px + (140 - 70) * ((100vw - 320px) / (1920 - 320)));
    height: 15px;
    display: block
}

.skeleton-loader .product-page .product-section .select-group .size-Select .size-box span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .product-page .product-section .select-group .size-Select .size-box span::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .product-page .product-section .select-group .size-Select>span {
    width: 24px;
    height: 24px;
    margin-left: auto;
    position: relative
}

.skeleton-loader .product-page .product-section .select-group .size-Select>span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .product-page .product-section .select-group .size-Select>span::after {
    background-image: unset;
    background-color: #e8e8e8
}

[dir="rtl"] .skeleton-loader .product-page .product-section .select-group .size-Select>span {
    margin-right: auto;
    margin-left: unset
}

.skeleton-loader .product-page .product-section .product-detail .product-detail-box h2 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative
}

.skeleton-loader .product-page .product-section .product-detail .product-detail-box h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .product-page .product-section .product-detail .product-detail-box p {
    line-height: 14px;
    margin-bottom: 5px;
    position: relative
}

.skeleton-loader .product-page .product-section .product-detail .product-detail-box p::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .product-page .product-section .product-detail .accordion .accordion-item .accordion-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.skeleton-loader .product-page .product-section .product-detail .accordion .accordion-item .accordion-header .accordion-button {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background-color: #f3f3f3;
    color: transparent;
    position: relative
}

.skeleton-loader .product-page .product-section .product-detail .accordion .accordion-item .accordion-header .accordion-button::after {
    display: none
}

.skeleton-loader .product-page .product-section .product-detail .accordion .accordion-item .accordion-header .accordion-button::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .product-page .product-section .product-detail .accordion .accordion-item .accordion-header span {
    width: 24px;
    height: 24px;
    position: relative
}

.skeleton-loader .product-page .product-section .product-detail .accordion .accordion-item .accordion-header span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .product-page .product-section .product-detail .accordion .accordion-item .accordion-body p {
    position: relative
}

.skeleton-loader .product-page .product-section .product-detail .accordion .accordion-item .accordion-body p::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .product-page .product-review .top-content h3 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative
}

.skeleton-loader .product-page .product-review .top-content h3::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .product-page .product-review .top-content a {
    position: relative
}

.skeleton-loader .product-page .product-review .top-content a::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .product-page .product-review .review-wrap .review-box {
    background-color: #f3f3f3
}

.skeleton-loader .product-page .product-review .review-wrap .review-box .media .img {
    width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
    height: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 100%;
    position: relative
}

.skeleton-loader .product-page .product-review .review-wrap .review-box .media .img::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .product-page .product-review .review-wrap .review-box .media .img::after {
    background-image: unset;
    background-color: #e8e8e8
}

.skeleton-loader .product-page .product-review .review-wrap .review-box .media .media-body h4 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    line-height: 18px;
    position: relative
}

.skeleton-loader .product-page .product-review .review-wrap .review-box .media .media-body h4::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .product-page .product-review .review-wrap .review-box .media .media-body h4::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .product-page .product-review .review-wrap .review-box .media .media-body .rating {
    margin-top: 5px
}

.skeleton-loader .product-page .product-review .review-wrap .review-box .media .media-body .rating .star {
    width: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    height: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    position: relative
}

.skeleton-loader .product-page .product-review .review-wrap .review-box .media .media-body .rating .star::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .product-page .product-review .review-wrap .review-box .media .media-body .rating .star::after {
    background-image: unset;
    background-color: #e8e8e8
}

.skeleton-loader .product-page .product-review .review-wrap .review-box p {
    position: relative
}

.skeleton-loader .product-page .product-review .review-wrap .review-box p::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .product-page .product-review .review-wrap .review-box p::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .media .link {
    width: calc(70px + (90 - 70) * ((100vw - 320px) / (1920 - 320)));
    height: calc(50px + (65 - 50) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    border-right: 1px solid #f1f1f1
}

[dir="rtl"] .skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .media .link {
    padding-right: unset;
    padding-left: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    border-left: 1px solid #f1f1f1;
    border-right: unset
}

.skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .media .link .img {
    width: 100%;
    height: 100%;
    background-color: #f3f3f3
}

.skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .media .media-body a {
    margin-bottom: 4px;
    position: relative
}

.skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .media .media-body a::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .media .media-body>span:first-of-type {
    margin-bottom: 4px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative
}

.skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .media .media-body>span:first-of-type::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .media .media-body>span:last-of-type span {
    position: relative;
    border-color: #fff
}

.skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .media .media-body>span:last-of-type span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .media .media-body .plus-minus {
    width: 60px;
    height: 30px;
    position: relative;
    position: absolute
}

.skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .media .media-body .plus-minus::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

@media only screen and (max-width: 375px) {
    .skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .media .media-body .plus-minus {
        position: relative
    }
}

.skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .delete-button {
    background-color: #f3f3f3
}

.skeleton-loader .cart-page .cart-item-wrap .swipe-to-show .delete-button span {
    width: calc(20px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    height: calc(20px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket {
    background-color: #f3f3f3
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .off {
    position: relative
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .off::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .off::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body h2 {
    width: 140px;
    height: 15px;
    position: relative;
    margin-top: 5px
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body h2::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body span {
    position: relative
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body span::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .code span {
    position: relative
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .code span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .code span::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .code a {
    background-color: #fff;
    position: relative
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .code a::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .cart-page .coupon-ticket-wrap .coupon-ticket .media .code a::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .cart-page .order-detail h3 {
    width: calc(110px + (120 - 110) * ((100vw - 320px) / (1920 - 320)));
    height: 22px;
    position: relative
}

.skeleton-loader .cart-page .order-detail h3::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .cart-page .order-detail ul li span {
    position: relative
}

.skeleton-loader .cart-page .order-detail ul li span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .address1-page .map-section {
    height: 50vh;
    width: 100%;
    position: relative
}

.skeleton-loader .address1-page .map-section::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .address1-page .map-section:after {
    width: 100%;
    height: 100%
}

.skeleton-loader .address1-page .location-section .media span {
    background-color: #f3f3f3;
    position: relative
}

.skeleton-loader .address1-page .location-section .media span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .address1-page .location-section .media .media-body h2 {
    position: relative
}

.skeleton-loader .address1-page .location-section .media .media-body h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .address1-page .location-section .location .location-box h3 {
    position: relative
}

.skeleton-loader .address1-page .location-section .location .location-box h3 .img {
    width: calc(24px + (28 - 24) * ((100vw - 320px) / (1920 - 320)));
    height: calc(24px + (28 - 24) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f3f3f3
}

.skeleton-loader .address1-page .location-section .location .location-box h3::before {
    position: absolute;
    content: "";
    width: 100px;
    left: 33px;
    height: 18px;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

[dir="rtl"] .skeleton-loader .address1-page .location-section .location .location-box h3::before {
    right: 33px;
    left: unset
}

.skeleton-loader .address1-page .location-section .location .location-box p {
    line-height: 15px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative
}

.skeleton-loader .address1-page .location-section .location .location-box p::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .address1-page .location-section .btn-solid {
    background-color: #f3f3f3;
    border-color: #f3f3f3
}

.skeleton-loader .address1-page .location-section .btn-solid span {
    display: block;
    margin: 0 auto;
    width: calc(100px + (200 - 100) * ((100vw - 320px) / (1920 - 320)));
    height: 15px;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-success-page .banner-section .content-wrap .heading {
    width: calc(251px + (309 - 251) * ((100vw - 320px) / (1920 - 320)));
    height: 20px;
    margin: 0 auto;
    margin-top: 23px;
    margin-bottom: 11px;
    position: relative
}

.skeleton-loader .order-success-page .banner-section .content-wrap .heading::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-success-page .banner-section .content-wrap p {
    position: relative;
    margin-left: auto;
    margin-right: auto
}

.skeleton-loader .order-success-page .banner-section .content-wrap p::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-success-page .banner-section .content-wrap .sk-2 {
    display: none
}

@media only screen and (max-width: 486px) {
    .skeleton-loader .order-success-page .banner-section .content-wrap .sk-2 {
        display: block
    }
}

.skeleton-loader .order-success-page .order-id-section .media {
    margin-top: -2px
}

.skeleton-loader .order-success-page .order-id-section .media>span {
    background-color: #fff;
    position: relative
}

.skeleton-loader .order-success-page .order-id-section .media>span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-success-page .order-id-section .media-body h2 {
    position: relative;
    margin-bottom: 4px
}

.skeleton-loader .order-success-page .order-id-section .media-body h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-success-page .order-id-section .media-body span {
    position: relative
}

.skeleton-loader .order-success-page .order-id-section .media-body span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-success-page .order-detail {
    background: #f3f3f3
}

.skeleton-loader .order-success-page .order-detail h3 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    line-height: 23px;
    position: relative
}

.skeleton-loader .order-success-page .order-detail h3::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-success-page .order-detail h3::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-success-page .order-detail ul li span {
    line-height: 20px;
    color: transparent;
    position: relative
}

.skeleton-loader .order-success-page .order-detail ul li span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-success-page .order-detail ul li span::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-success-page .order-detail ul li span a {
    color: transparent !important
}

.skeleton-loader .order-tracking-page .map-section {
    background-color: #f3f3f3
}

.skeleton-loader .order-tracking-page .location-section .time-box span {
    margin: 0 auto;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative
}

.skeleton-loader .order-tracking-page .location-section .time-box span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-tracking-page .location-section .time-box .heading {
    margin: 0 auto;
    width: calc(200px + (300 - 200) * ((100vw - 320px) / (1920 - 320)));
    height: 20px;
    margin-top: 4px;
    position: relative
}

.skeleton-loader .order-tracking-page .location-section .time-box .heading::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-tracking-page .location-section .current-box .media .img {
    width: calc(46px + (55 - 46) * ((100vw - 320px) / (1920 - 320)));
    height: calc(46px + (55 - 46) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 100%;
    position: relative
}

.skeleton-loader .order-tracking-page .location-section .current-box .media .img::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-tracking-page .location-section .current-box .media-body h2 {
    position: relative;
    margin-bottom: 2px
}

.skeleton-loader .order-tracking-page .location-section .current-box .media-body h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-tracking-page .location-section .current-box .media-body span {
    position: relative
}

.skeleton-loader .order-tracking-page .location-section .current-box .media-body span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-tracking-page .location-section .current-box .action-box span {
    background-color: #f3f3f3;
    border-color: #f3f3f3;
    position: relative
}

.skeleton-loader .order-tracking-page .location-section .current-box .action-box span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-tracking-page .location-section .tracking-box .media>span {
    background-color: #f3f3f3;
    border-color: #f3f3f3;
    position: relative
}

.skeleton-loader .order-tracking-page .location-section .tracking-box .media>span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-tracking-page .location-section .tracking-box .media-body h3 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative;
    margin-bottom: 3px
}

.skeleton-loader .order-tracking-page .location-section .tracking-box .media-body h3::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-tracking-page .location-section .tracking-box .media-body span {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    line-height: 15px;
    position: relative
}

.skeleton-loader .order-tracking-page .location-section .tracking-box .media-body span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-history .nav {
    background-color: #f3f3f3
}

.skeleton-loader .order-history .nav .nav-item .nav-link {
    position: relative
}

.skeleton-loader .order-history .nav .nav-item .nav-link::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-history .nav .nav-item .nav-link::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box {
    background-color: #f3f3f3
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .media>a h2 {
    position: relative
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .media>a h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .media>a h2::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .media>a p {
    position: relative
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .media>a p::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .media>a p::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .media>a span {
    position: relative
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .media>a span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .media>a span::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .media .media-body .img {
    width: calc(75px + (100 - 75) * ((100vw - 320px) / (1920 - 320)));
    height: calc(75px + (100 - 75) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 10px;
    position: relative
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .media .media-body .img::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .media .media-body .img::after {
    background-image: unset;
    background-color: #e8e8e8
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .bottom-content a {
    position: relative
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .bottom-content a::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .bottom-content a::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-history .tab-content .tab-pane .order-box .bottom-content .rating .star {
    width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    height: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff
}

.skeleton-loader .order-detail .banner-box {
    background-color: #f3f3f3
}

.skeleton-loader .order-detail .banner-box .media .img {
    width: calc(40px + (45 - 40) * ((100vw - 320px) / (1920 - 320)));
    height: calc(40px + (45 - 40) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    border-radius: 4px
}

.skeleton-loader .order-detail .banner-box .media .media-body span {
    position: relative
}

.skeleton-loader .order-detail .banner-box .media .media-body span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-detail .banner-box .media .media-body span::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-detail .banner-box .media .media-body h2 {
    position: relative;
    margin-top: 4px
}

.skeleton-loader .order-detail .banner-box .media .media-body h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-detail .banner-box .media .media-body h2::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-detail .item-section h3 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative
}

.skeleton-loader .order-detail .item-section h3::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-detail .item-section .item-wrap .media .count span {
    background-color: #f3f3f3;
    position: relative
}

.skeleton-loader .order-detail .item-section .item-wrap .media .count span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .order-detail .item-section .item-wrap .media .count .icon {
    background-color: #f3f3f3;
    width: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    height: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.skeleton-loader .order-detail .item-section .item-wrap .media .media-body h4 {
    position: relative;
    margin-bottom: 3px;
    line-height: 22px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

.skeleton-loader .order-detail .item-section .item-wrap .media .media-body h4::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-detail .item-section .item-wrap .media .media-body span {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative;
    line-height: 20px
}

.skeleton-loader .order-detail .item-section .item-wrap .media .media-body span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-detail .item-section .item-wrap .media span {
    position: relative
}

.skeleton-loader .order-detail .item-section .item-wrap .media span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-detail .order-summary h3 {
    position: relative;
    line-height: 22px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
}

.skeleton-loader .order-detail .order-summary h3::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-detail .order-summary ul li span {
    position: relative
}

.skeleton-loader .order-detail .order-summary ul li span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-detail .address-section h3 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative;
    line-height: 16px
}

.skeleton-loader .order-detail .address-section h3::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-detail .address-section .address h4 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-bottom: 3px;
    position: relative
}

.skeleton-loader .order-detail .address-section .address h4::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-detail .address-section .address p {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative
}

.skeleton-loader .order-detail .address-section .address p::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-detail .payment-method h3 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative
}

.skeleton-loader .order-detail .payment-method h3::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .order-detail .payment-method .payment-box .img {
    width: 50px;
    height: 50px;
    background-color: #f3f3f3
}

.skeleton-loader .order-detail .payment-method .payment-box span {
    position: relative
}

.skeleton-loader .order-detail .payment-method .payment-box span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .coupon-ticket {
    background-color: #f3f3f3
}

.skeleton-loader .coupon-ticket .media .off {
    position: relative
}

.skeleton-loader .coupon-ticket .media .off::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .coupon-ticket .media .off::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

@media only screen and (max-width: 375px) {
    .skeleton-loader .coupon-ticket .media .off {
        margin-bottom: 26px !important
    }
}

.skeleton-loader .coupon-ticket .media .media-body h2 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative;
    margin-top: 3px
}

.skeleton-loader .coupon-ticket .media .media-body h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .coupon-ticket .media .media-body h2::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .coupon-ticket .media .media-body span {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative
}

.skeleton-loader .coupon-ticket .media .media-body span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .coupon-ticket .media .media-body span::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .coupon-ticket .media .code span {
    position: relative
}

.skeleton-loader .coupon-ticket .media .code span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .coupon-ticket .media .code span::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .coupon-ticket .media .code a {
    background-color: #f3f3f3;
    position: relative
}

.skeleton-loader .coupon-ticket .media .code a::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .coupon-ticket .media .code a::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .notification-page .nav-wrap {
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    background-color: #f3f3f3;
    padding: 6px calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.skeleton-loader .notification-page .nav-wrap .nav-tab2 {
    background-color: #f3f3f3;
    gap: 10px
}

.skeleton-loader .notification-page .nav-wrap .nav-tab2 button {
    border: none;
    padding: 0 calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    position: relative
}

.skeleton-loader .notification-page .nav-wrap .nav-tab2 button::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .notification-page .nav-wrap .nav-tab2 button::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .notification-page .nav-wrap>button {
    position: relative
}

.skeleton-loader .notification-page .nav-wrap>button::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .notification-page .nav-wrap>button::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .notification-page .tab-pane .offer-wrap h2 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative
}

.skeleton-loader .notification-page .tab-pane .offer-wrap h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .notification-page .tab-pane .offer-wrap .offer-box .media .icon-wrap {
    background-color: #f3f3f3 !important
}

.skeleton-loader .notification-page .tab-pane .offer-wrap .offer-box .media .icon-wrap .img {
    background-color: #fff;
    border-radius: 2px;
    width: calc(25px + (32 - 25) * ((100vw - 320px) / (1920 - 320)));
    height: calc(25px + (32 - 25) * ((100vw - 320px) / (1920 - 320)))
}

.skeleton-loader .notification-page .tab-pane .offer-wrap .offer-box .media .media-body h3 {
    position: relative;
    margin-bottom: 6px
}

.skeleton-loader .notification-page .tab-pane .offer-wrap .offer-box .media .media-body h3::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .notification-page .tab-pane .offer-wrap .offer-box .media .media-body span {
    position: relative
}

.skeleton-loader .notification-page .tab-pane .offer-wrap .offer-box .media .media-body span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .error-404 .error h2,
.skeleton-loader .empty-cart .error h2,
.skeleton-loader .no-order .error h2,
.skeleton-loader .empty-cart .error h2,
.skeleton-loader .no-order .error h2 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    position: relative
}

.skeleton-loader .error-404 .error h2::after,
.skeleton-loader .empty-cart .error h2::after,
.skeleton-loader .no-order .error h2::after,
.skeleton-loader .empty-cart .error h2::after,
.skeleton-loader .no-order .error h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .error-404 .error h2::before,
.skeleton-loader .empty-cart .error h2::before,
.skeleton-loader .no-order .error h2::before,
.skeleton-loader .empty-cart .error h2::before,
.skeleton-loader .no-order .error h2::before {
    background-color: #f3f3f3
}

.skeleton-loader .error-404 .error .sk-1,
.skeleton-loader .empty-cart .error .sk-1,
.skeleton-loader .no-order .error .sk-1,
.skeleton-loader .empty-cart .error .sk-1,
.skeleton-loader .no-order .error .sk-1 {
    width: 95%;
    height: 18px
}

.skeleton-loader .error-404 .error .sk-2,
.skeleton-loader .empty-cart .error .sk-2,
.skeleton-loader .no-order .error .sk-2,
.skeleton-loader .empty-cart .error .sk-2,
.skeleton-loader .no-order .error .sk-2 {
    width: 85%;
    height: 18px
}

.skeleton-loader .error-404 .error .sk-3,
.skeleton-loader .empty-cart .error .sk-3,
.skeleton-loader .no-order .error .sk-3,
.skeleton-loader .empty-cart .error .sk-3,
.skeleton-loader .no-order .error .sk-3 {
    width: 75%;
    height: 18px;
    display: none
}

@media only screen and (max-width: 455px) {

    .skeleton-loader .error-404 .error .sk-3,
    .skeleton-loader .empty-cart .error .sk-3,
    .skeleton-loader .no-order .error .sk-3,
    .skeleton-loader .empty-cart .error .sk-3,
    .skeleton-loader .no-order .error .sk-3 {
        display: block
    }
}

.skeleton-loader .error-404 .error .sk-4,
.skeleton-loader .empty-cart .error .sk-4,
.skeleton-loader .no-order .error .sk-4,
.skeleton-loader .empty-cart .error .sk-4,
.skeleton-loader .no-order .error .sk-4 {
    width: 65%;
    height: 18px;
    display: none
}

@media only screen and (max-width: 351px) {

    .skeleton-loader .error-404 .error .sk-4,
    .skeleton-loader .empty-cart .error .sk-4,
    .skeleton-loader .no-order .error .sk-4,
    .skeleton-loader .empty-cart .error .sk-4,
    .skeleton-loader .no-order .error .sk-4 {
        display: block
    }
}

.skeleton-loader .error-404 .error .sk-1,
.skeleton-loader .empty-cart .error .sk-1,
.skeleton-loader .no-order .error .sk-1,
.skeleton-loader .error-404 .error .sk-2,
.skeleton-loader .empty-cart .error .sk-2,
.skeleton-loader .no-order .error .sk-2,
.skeleton-loader .error-404 .error .sk-3,
.skeleton-loader .empty-cart .error .sk-3,
.skeleton-loader .no-order .error .sk-3,
.skeleton-loader .error-404 .error .sk-4,
.skeleton-loader .empty-cart .error .sk-4,
.skeleton-loader .no-order .error .sk-4,
.skeleton-loader .empty-cart .error .sk-1,
.skeleton-loader .no-order .error .sk-1,
.skeleton-loader .empty-cart .error .sk-2,
.skeleton-loader .no-order .error .sk-2,
.skeleton-loader .empty-cart .error .sk-3,
.skeleton-loader .no-order .error .sk-3,
.skeleton-loader .empty-cart .error .sk-4,
.skeleton-loader .no-order .error .sk-4 {
    margin-top: 0;
    margin-bottom: 6px !important
}

.skeleton-loader .error-404 .error p,
.skeleton-loader .empty-cart .error p,
.skeleton-loader .no-order .error p,
.skeleton-loader .empty-cart .error p,
.skeleton-loader .no-order .error p {
    position: relative;
    margin-left: auto;
    margin-right: auto
}

.skeleton-loader .error-404 .error p::after,
.skeleton-loader .empty-cart .error p::after,
.skeleton-loader .no-order .error p::after,
.skeleton-loader .empty-cart .error p::after,
.skeleton-loader .no-order .error p::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .error-404 .error a,
.skeleton-loader .empty-cart .error a,
.skeleton-loader .no-order .error a,
.skeleton-loader .empty-cart .error a,
.skeleton-loader .no-order .error a {
    margin-top: calc(19px + (25 - 19) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f3f3f3;
    border-color: #f3f3f3
}

.skeleton-loader .error-404 .error a span,
.skeleton-loader .empty-cart .error a span,
.skeleton-loader .no-order .error a span,
.skeleton-loader .empty-cart .error a span,
.skeleton-loader .no-order .error a span {
    display: block;
    margin: 0 auto;
    width: 107px;
    height: 21px
}

.skeleton-loader .search-page .recent-search h2 {
    width: 150px;
    overflow: hidden;
    height: 23px;
    position: relative
}

.skeleton-loader .search-page .recent-search h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .search-page .recent-search ul li {
    padding: 9px 20px;
    background-color: #f3f3f3
}

.skeleton-loader .search-page .recent-search ul li span {
    width: 60px;
    display: block;
    margin: 0 auto;
    height: 15px;
    position: relative
}

.skeleton-loader .search-page .recent-search ul li span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .search-page .recent-search ul li span::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .search-page .trending h2 {
    width: 150px;
    overflow: hidden;
    height: 23px;
    position: relative
}

.skeleton-loader .search-page .trending h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .search-page .trending .category {
    background-color: #f3f3f3 !important;
    border-color: #f3f3f3 !important
}

.skeleton-loader .search-page .trending .category .img {
    width: calc(45px + (60 - 45) * ((100vw - 320px) / (1920 - 320)));
    height: calc(45px + (60 - 45) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 4px;
    background-color: #fff;
    margin: 0 auto
}

.skeleton-loader .search-page .trending-products h3 {
    position: relative;
    width: 150px;
    overflow: hidden;
    height: 23px;
    overflow: hidden
}

.skeleton-loader .search-page .trending-products h3::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .search-page .trending-products .product-wrap .media {
    background-color: #f3f3f3
}

.skeleton-loader .search-page .trending-products .product-wrap .media .link {
    width: calc(70px + (90 - 70) * ((100vw - 320px) / (1920 - 320)));
    height: calc(50px + (65 - 50) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    border-right: 1px solid #f1f1f1
}

[dir="rtl"] .skeleton-loader .search-page .trending-products .product-wrap .media .link {
    padding-left: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    padding-right: unset;
    border-left: 1px solid #f1f1f1;
    border-right: unset
}

.skeleton-loader .search-page .trending-products .product-wrap .media .link .img {
    width: 100%;
    border-radius: 4px;
    height: 100%;
    background-color: #fff
}

.skeleton-loader .search-page .trending-products .product-wrap .media .media-body a {
    position: relative
}

.skeleton-loader .search-page .trending-products .product-wrap .media .media-body a::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .search-page .trending-products .product-wrap .media .media-body a::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .search-page .trending-products .product-wrap .media .media-body>span:first-of-type {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-top: 4px;
    position: relative
}

.skeleton-loader .search-page .trending-products .product-wrap .media .media-body>span:first-of-type::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .search-page .trending-products .product-wrap .media .media-body>span:first-of-type::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .search-page .trending-products .product-wrap .media .media-body>span:last-of-type span {
    margin-top: 4px;
    position: relative;
    border-color: #fff
}

.skeleton-loader .search-page .trending-products .product-wrap .media .media-body>span:last-of-type span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .search-page .trending-products .product-wrap .media .media-body>span:last-of-type span::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .help-page .nav {
    background-color: #f3f3f3
}

.skeleton-loader .help-page .nav .nav-item button {
    position: relative
}

.skeleton-loader .help-page .nav .nav-item button::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .help-page .nav .nav-item button::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .help-page .tab-content .tab-pane>h2 {
    margin-bottom: 10px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 90%;
    overflow: hidden;
    position: relative
}

.skeleton-loader .help-page .tab-content .tab-pane>h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .help-page .tab-content .tab-pane>h2:after {
    left: -1px
}

.skeleton-loader .help-page .tab-content .accordion .accordion-item .accordion-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.skeleton-loader .help-page .tab-content .accordion .accordion-item .accordion-header .button {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    display: block;
    width: 160px;
    height: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    position: relative
}

.skeleton-loader .help-page .tab-content .accordion .accordion-item .accordion-header .button::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .help-page .tab-content .accordion .accordion-item .accordion-header .button span {
    width: 24px;
    height: 24px;
    position: relative
}

.skeleton-loader .help-page .tab-content .accordion .accordion-item .accordion-header .button span::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .help-page .tab-content .accordion .accordion-item .accordion-body p {
    position: relative
}

.skeleton-loader .help-page .tab-content .accordion .accordion-item .accordion-body p::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .about-us-page .banner-box {
    position: relative
}

.skeleton-loader .about-us-page .banner-box::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .about-us-page .banner-box:after {
    height: 110%
}

.skeleton-loader .about-us-page .who-we-are h2 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative;
    line-height: 22px
}

.skeleton-loader .about-us-page .who-we-are h2::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .about-us-page .who-we-are p {
    width: 100%;
    margin-top: 5px;
    height: 15px;
    position: relative
}

.skeleton-loader .about-us-page .who-we-are p::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .about-us-page .who-we-are .sk-3 {
    width: 70%
}

.skeleton-loader .about-us-page .how-do-section h3 {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative;
    line-height: 22px
}

.skeleton-loader .about-us-page .how-do-section h3::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .about-us-page .how-do-section .steps-wrap .steps-box {
    background-color: #f3f3f3
}

.skeleton-loader .about-us-page .how-do-section .steps-wrap .steps-box span {
    background-color: #e8e8e8;
    color: transparent
}

.skeleton-loader .about-us-page .how-do-section .steps-wrap .steps-box .content h4 {
    height: 18px;
    position: relative
}

.skeleton-loader .about-us-page .how-do-section .steps-wrap .steps-box .content h4::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .about-us-page .how-do-section .steps-wrap .steps-box .content h4::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

.skeleton-loader .about-us-page .how-do-section .steps-wrap .steps-box .content .sk-2 {
    display: none
}

@media only screen and (max-width: 515px) {
    .skeleton-loader .about-us-page .how-do-section .steps-wrap .steps-box .content .sk-2 {
        display: block
    }
}

.skeleton-loader .about-us-page .how-do-section .steps-wrap .steps-box .content p {
    height: 16px;
    position: relative
}

.skeleton-loader .about-us-page .how-do-section .steps-wrap .steps-box .content p::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: inherit;
    width: 101%;
    height: 101%;
    border: 1px solid #f3f3f3;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #f3f3f3
}

.skeleton-loader .about-us-page .how-do-section .steps-wrap .steps-box .content p::after {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, #e8e8e8), color-stop(18%, #e4e4e4), color-stop(33%, #e8e8e8));
    background-image: linear-gradient(90deg, #e8e8e8 8%, #e4e4e4 18%, #e8e8e8 33%);
    -webkit-animation: skeletonAnimation 4s linear infinite forwards;
    animation: skeletonAnimation 4s linear infinite forwards
}

/*# sourceMappingURL=style.css.map */