.pointer{
  cursor: pointer !important;
}
.bg-color-1 {
  background: #0f6c37 !important;
}

.bg-page{
  background-color: #f8f8f8;
}
.bg-page-dark{
  background-color: rgb(238, 238, 238);
}


.bg-semi-transparent{
  background-color: rgb(125 125 125 / 71%) !important;
}
.header {
  transition: 0.3s;
}

.header-wrap {
  margin: 0 auto;
  max-width: 800px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3
}
.unmain-wrap{
  margin-left: -15px !important;
    margin-right: -15px !important;
}
.inside-wrap {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

@media only screen and (min-width: 800px) {
  .header-wrap {
    max-width: 767px
  }
}

.card-poin{
  background-color: #f8f8f8;
    border: 1px solid #eee;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 1px 0 rgb(0 0 0 / 4%);
}
.swiper-grid-column>.swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: row;
}

.card-box{
  padding: 5px;
}
.card-box span{
  font-size:calc(12px + (17 - 14) * ((100vw - 320px) / (1920 - 320))) !important;
}
.card-box img {
  max-width: 55px;
  margin-bottom:7px;
}

.rounded-50p{
  border-radius:50px
}


#search-box-input{
      background-color: #fff !important;
}

/* .offcanvas-bottom{
  top:0;
} */
.offcanvas{
  max-width: 782px;
}
.offcanvas.show{

  visibility: visible;
}

.product-card .img-container {
  background-color: #efefef;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 1 / 1;
  width: 100%;
}
.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}
.main-wrap-height{
  height: calc(100vH - 56px);
}
.text-limit-div{
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.text-limit-2rows{
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2 !important;
    overflow: hidden;
}
.text-limit-1row{
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1 !important;
    overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}
.order-box{
  background-color: #fff;

  padding: 15px;
  border-radius: 10px;
}
.order-box-active {
  background-color: #f5daff;
  padding: 15px;
  border-radius: 10px;
}

.Snackbar_snackbar-wrapper__ocbPJ, .Snackbar_snackbar-wrapper-bottom-center__21ghq{
  z-index: 10000 !important;
}
.card-list-checked{
      padding: 2px 8px 4px;
        border-radius: 0px 10px 0px 10px;
        background-color: #0f6c37;
            transform: scale(0.8);
                transition: all 0.3s ease;
        position: absolute;
        right: 13px;
        top: 5px;
        
}
.card-list-active{
  border: #0f6c37 solid 1px;
}
.img-circular {
  width: 55px !important;
  height: 55px !important;
  background-image: url('https://gadgetren.com/wp-content/uploads/2021/08/Cara-Melihat-Rating-Grabfood-Merchant-Header.jpg');
  background-size: cover;
  display: block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.default-card-shadow{
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 1px 0 rgb(0 0 0 / 4%);
}

.alamat-detail:before{
  content: "";
    display: block;
    background-image: url(https://evermos.com/border-address.png);
    height: 4px;
    background-repeat: repeat-x;
    background-size: auto 4px;
}

.plus-minus{
  box-shadow: 0 3px 6px rgb(0 0 0 / 5%),
    0 3px 6px rgb(0 0 0 / 14%);
}
.scrollable-hiddenbar {
  -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}
.scrollable-hiddenbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

._loading_overlay_overlay{
  z-index: 1080 !important;
  position: fixed !important;

}